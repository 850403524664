import {defaultService} from '../../store/service/'
import {useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'

import {alertAtom} from '../../store/recoil/alert'
import {loaderAtom} from '../recoil/loader'

export const SocialAction = () => {

  const navigate = useNavigate()
  const setAlert = useSetRecoilState(alertAtom)
  const setLoader = useSetRecoilState(loaderAtom)

  const socialLogin = (email: string) => {
    setLoader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/social/login',
      params: {
        email: email
      }
    }).then(
      response => {
        setLoader({isLoading: false})
        if (response.data.status === 200) {
          console.log(response.data)
          // alert(response.data.message);
          // router.push('/');
        } else if (response.data.status === 400) {
          // alert(response.data.message);
        } else {
          // alert('문제가 발생했습니다. 관리자에게 문의주세요.')
        }
      },
      error => {
        setLoader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true,
        })
      }
    )
  }

  const getKakaoToken = async (params: any) => {
    setLoader({isLoading: true})
    defaultService.handleService({
      standAlone: true,
      method: 'post',
      endPoint: `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&code=${params}`
    }).then(
      response => {
        setLoader({isLoading: false})
        getKakaoUserInfo(response.data.access_token)
      },
      error => {
        setLoader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true,
        })
      }
    )
  }

  const getKakaoUserInfo = async (params: any) => {
    setLoader({isLoading: true})
    defaultService.handleService({
      standAlone: true,
      method: 'post',
      endPoint: `https://kapi.kakao.com/v2/user/me`,
      headers: {
        authorization: `Bearer ${params}`
      }
    }).then(
      response => {
        setLoader({isLoading: false})
        console.log(response.data)
        // checkUser({
        //   provider: 'kakao',
        //   user: {
        //     name: response.data.kakao_account.name,
        //     email: response.data.kakao_account.email,
        //     mobile: response.data.kakao_account.phone_number
        //   }
        // })
      },
      error => {
        setLoader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true,
        })
      }
    )
  }

  const getNaverToken = async (params: any) => {
    setLoader({isLoading: true})
    defaultService.handleService({
      standAlone: true,
      method: 'post',
      endPoint: `https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&client_secret=${process.env.REACT_APP_NAVER_CLIENT_SECRET}&code=${params}`
    }).then(
      response => {
        setLoader({isLoading: false})
        // getNaverUserInfo(response.data.access_token)
      },
      error => {
        setLoader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true,
        })
      }
    )
  }

  const getNaverUserInfo = async (params: any) => {
    setLoader({isLoading: true})
    defaultService.handleService({
      standAlone: true,
      method: 'post',
      endPoint: `/v1/nid/me`,
      headers: {
        Authorization: `Bearer ${params}`
      }
    }).then(
      response => {
        setLoader({isLoading: false})
        console.log(response.data)
        // checkUser({
        //   provider: 'naver',
        //   user: {
        //     name: response.data.kakao_account.name,
        //     email: response.data.kakao_account.email,
        //     mobile: response.data.kakao_account.phone_number
        //   }
        // })
      },
      error => {
        setLoader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true,
        })
      }
    )
  }

  const checkUser = async (params: any) => {
    setLoader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: `/social/checkUser`,
      params: params
    }).then(
      response => {
        setLoader({isLoading: false})
        // if (response.data.status === 400) {
        //   navigate('/signUp',  { replace: false})
        // } else {
        //   // socialLogin(response.data.payload.email)
        // }
        // navigate('/',  { replace: false})
      },
      error => {
        setLoader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true,
        })
      }
    )
  }

  return {
    getKakaoToken,
    getNaverToken,
    checkUser,
    socialLogin
  }
}
