import React, {useState} from 'react'
import Svg from '../../module/svg/'
import {authAtom} from '../../../store/recoil/auth'
import {useRecoilValue} from 'recoil'
import {Style} from './style/Nav'

const category = [
  {
    id: 0,
    label: '기프트샵 정보',
    sub: [
      {
        id: 's0',
        label: '상품권 관리',
        path: '/admin/gift'
      },
      {
        id: 's0',
        label: '카테고리 관리',
        path: '/admin/category'
      },
      {
        id: 's0',
        label: '교환·반품·품절 안내',
        path: '/admin/refund'
      }
    ]
  },
  {
    id: 1,
    label: '상품권 발행 정보',
    sub: [
      {
        id: 's0',
        label: '주문 전체',
        path: '/admin/product/'
      },
      {
        id: 's0',
        label: '발행 관리',
        path: '/admin/product/send'
      },
      {
        id: 's0',
        label: '취소 관리',
        path: '/admin/product/cancel'
      }
    ]
  },
  {
    id: 2,
    label: '사용자 정보',
    sub: [
      {
        id: 's0',
        label: '관리자 목록',
        path: '/admin/user/admin'
      },
      {
        id: 's0',
        label: '회원 목록',
        path: '/admin/user'
      },
      {
        id: 's0',
        label: '탈퇴 회원',
        path: '/admin/user/reject'
      },
      {
        id: 's0',
        label: '이용 약관',
        path: '/admin/policy'
      }
    ]
  },
  {
    id: 3,
    label: '가격별 주문명칭',
    path: '/admin/name'
  },
  {
    id: 4,
    label: '문의 관리',
    path: '/admin/qna'
  },
  {
    id: 5,
    label: '배너 관리',
    path: '/admin/banner'
  },
  {
    id: 6,
    label: '팝업 관리',
    path: '/admin/popup'
  },
  {
    id: 7,
    label: '고객 센터',
    sub: [
      {
        id: 's0',
        label: '공지사항',
        path: '/admin/notice'
      },
      {
        id: 's0',
        label: '자주 묻는 질문',
        path: '/admin/faq'
      }
    ]
  }
]

const Index = () => {

  const auth = useRecoilValue(authAtom)
  const [isOpen, setIsOpen] = useState<any>([0, 1, 2, 7])

  const getStatus = (id: number) => {
    return isOpen.findIndex((element: any) => element === id) > -1 ? true : false
  }

  const handleChange = (id: number) => {
    setIsOpen(() => {
      return (
        getStatus(id) ? isOpen.filter((item: any) => item !== id) : isOpen.concat(id)
      )
    })
  }

  return (
    <Style.Container>
      <Style.Nav>
        {category.map((item: any, index: number) => {
          return (
            <Style.NavItem key={index}>
              {
                !item.path ?
                  <Style.NavItemLabel
                    selected={getStatus(item.id)}
                    onClick={() => handleChange(item.id)}
                  >
                    {item.label}
                    {
                      item.sub &&
                        <Svg
                            icon={'arrow'}
                            width={10}
                            height={10}
                            color={'#666666'}
                        />
                    }
                  </Style.NavItemLabel>
                  :
                  <Style.NavLinkItemLabel to={item.path}>
                    {item.label}
                  </Style.NavLinkItemLabel>
              }

              {item.sub &&
                  <Style.NavSubItem selected={getStatus(item.id)}>
                    {item.sub.map((sub: any, subIndex: number) => {
                      return (
                        <Style.NavSubItemLabel
                          key={subIndex}
                          to={sub.path}
                          end
                        >
                          {sub.label}
                        </Style.NavSubItemLabel>
                      )
                    })}
                  </Style.NavSubItem>}
            </Style.NavItem>
          )
        })}
      </Style.Nav>
    </Style.Container>
  )
}

export default Index
