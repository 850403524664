import React, { useState } from 'react'
import { JoinWrap, SubTitleWrap, TitleWrap } from './styles'
import Step01 from './Step01'
import Step02 from './Step02'
import Step03 from './Step03'

const Join = () => {

  const [step, setStep] = useState(0)

  return (
    <JoinWrap>
      <div className='container'>
        <TitleWrap>
          <h2>회원가입</h2>
        </TitleWrap>
        <div className="join-wrap">
          <SubTitleWrap className={step === 0 ? 'active' : ''}>
            <div className="num">1</div>
            <h3>약관동의</h3>
          </SubTitleWrap>
          <Step01 
            handleStep={() => setStep(1)}
            active={step === 0}
          />
          <SubTitleWrap className={step === 1 ? 'active' : ''}>
            <div className="num">2</div>
            <h3>정보입력</h3>
          </SubTitleWrap>
          <Step02 
            active={step === 1}
            handleStep={() => setStep(2)}
          />
          <SubTitleWrap className={step === 2 ? 'active' : ''}>
            <div className="num">3</div>
            <h3>가입완료</h3>
          </SubTitleWrap>
          <Step03 
            active={step === 2}
            handleStep={() => setStep(3)}
          />
        </div>
      </div>
    </JoinWrap>
  )
}

export default Join