import styled from "styled-components";

export const ModalWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  @media screen and (max-width: 500px) {
    padding: 0 1.5rem;
    > div {
      &:not(.bg) {
        width: 100% !important;
        height: 300px !important;
      }
    }
  }
`