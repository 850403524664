import styled from "styled-components";

export const OrderBoxWrap = styled.div`
  display: flex;
  padding: 3rem;
  background-color: #f5f5f5;
  gap: 4rem;
  margin-top: 3rem;
  > div {
    width: 50%;
  }
  .order-type {
    button {
      width: 50%;
    }
  }
  .coupon-wrap {
    background-color: #fff;
    border-radius: 0.4rem;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    padding: 1.6rem;
    margin-top: 1.6rem;
    h4 {
      font-size: 2rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 3rem;
    }
    .phone-wrap {
      display: flex;
      gap: 1.6rem;
    }
    .info-text {
      margin-top: 1rem;
      span {
        display: block;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        word-break: keep-all;
      }
    }
  }
  .price-wrap {
    background-color: #fff;
    border-radius: 0.4rem;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    margin-bottom: 1.6rem;
    dl {
      width: 100%;
      height: 5.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.6rem;
      &:not(:last-child) {
        border-bottom: 0.1rem solid #dfdfdf;
      }
    }
  }
  @media screen and (max-width: 1048px) {
    display: block;
    > div {
      width: 100%;
    }
    .right-box {
      margin-top: 1.6rem;
    }
    .order-type {
      button {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 1.5rem;
    .coupon-wrap {
      padding: 1.5rem;
      h4 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
      .info-text {
        span {
          font-size: 1.2rem;
        }
      }
    }
    .price-wrap {
      dl {
        height: 5rem;
      }
    }
  }
`