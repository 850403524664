import React, { useEffect, useState } from 'react'
import { OrderHistoryWrap } from './styles'
import { OrderHistoryProps } from './types'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Pagination from '../pagination';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../store/recoil/auth';
import { OrderAction } from '../../store/action';

const OrderHistory = () => {

  // navigate
  const navigate = useNavigate()

  // 유저 정보
  const auth = useRecoilValue(authAtom)

  // location
  const location = useLocation()
  const currentPage = new URLSearchParams(location.search).get('page') || 0

  // Order Action
  const {getOrderMyHistory} = OrderAction()

  // pagination
  const [pagination, setPagination] = useState({
    page: Number(currentPage),
    size: 10
  })

  useEffect(() => {
    setPagination({
      ...pagination,
      page: Number(currentPage)
    })
  }, [location])

  const [totalPage, setTotalPage] = useState(0)

  const handleChangePage = (i: number) => {
    navigate(`/my/order?page=${i}`)
  }

  // 주문내역
  const [orderList, setOrderList] = useState<OrderHistoryProps[]>([])

  // 로그인 체크
  useEffect(() => {
    if (!auth.token) {
      navigate('/')
    } else {
      getOrderMyHistory({
        page: pagination.page,
        size: pagination.size,
        user_id: auth?.id
      }).then((res) => {
        setOrderList(res.data)
        setTotalPage(res.pageInfo.total)
      })
    }
  }, [auth, pagination])

  return (
    <OrderHistoryWrap>
      <ul className='boad-list'>
        <li className='hd'>
          <div className="order-id">주문번호</div>
          <div className="title">구매상품</div>
          <div className="price">주문금액</div>
          <div className="issue-status">발행상태</div>
          <div className="payment-status">결제상태</div>
          <div className="date">주문일자</div>
        </li>
        {
          orderList?.length > 0 ? orderList.map((item, index) => {
            return (
              <li key={index}>
                <div className="order-id">{item.order_no}</div>
                <div className="title">{item.product_name}</div>
                <div className="price">{item.total.toLocaleString('ko-KR')}</div>
                <div className="issue-status">
                  {
                    item.publish_status === 0 ? '미발행' : item.publish_status === 1 ? '발행' : '취소'
                  }
                </div>
                <div className="payment-status">
                  {
                    item.pay_status === 0 ? '결제완료' : '환불완료'
                  }
                </div>
                <div className="date">{moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')}</div>
              </li>
            )
          }) : (
            <li>
              <div className='empty'>
                구매한 상품권이 없습니다.
              </div>
            </li>
          )
        }
      </ul>
      {
        orderList?.length > 0 && (
          <Pagination
            currentPage={pagination.page} 
            totalPages={totalPage}
            onPageChange={(i) => handleChangePage(i)}
            size={pagination.size}
          />
        )
      }
    </OrderHistoryWrap>
  )
}

export default OrderHistory