import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`

const Content = styled.div`
  display: flex;
  margin-top: 1rem;
`

const Section = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 24rem;
  height: fit-content;
`

const Action = styled.div`
  display: flex;
  justify-content: space-between;
`

const Category = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 24rem;
  max-width: 24rem;
  margin: .8rem 0 0 4rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  border: .1rem solid #efebef;
`

const Nodata = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`

export const Style = {
  Container,
  Content,
  Section,
  Action,
  Category,
  Nodata
}
