import {atom} from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

const authAtom = atom({
  key: 'authAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export {authAtom}
