import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import { boardAtom, boardListAtom } from '../recoil/board'

interface GetBoardListProps {
  page: number
  size: number
  searchType: string
  keyword: string
}

interface GetBoardDetailProps {
  id: number
}

interface BoardPassCheckProps {
  password: string
  id: number
}

interface BoardInquiryProps {
  author: string
  email: string
  isSecrete: boolean
  password: string
  content: string
  title: string
  ask_by: number
  files: any
}

export const BoardAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setBoardList = useSetRecoilState(boardListAtom)
  const setBoard = useSetRecoilState(boardAtom)

  const getBoardList = (params: GetBoardListProps) => {

    loader({isLoading: true})

    defaultService.handleService({
      method: 'post',
      endPoint: '/board/list',
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        setBoardList(response.data.payload)
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getBoardDetail = (params: GetBoardDetailProps) => {

    loader({isLoading: true})

    defaultService.handleService({
      method: 'post',
      endPoint: '/board/detail',
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        setBoard(response.data.payload.data)
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const boardPassCheck = async (params: BoardPassCheckProps) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/board/pass',
      params: params
    }).then((res) => {
      loader({isLoading: false})
      return res.data
    }, (error) => {
      loader({isLoading: false})
      setAlert({
        title: error.name,
        type: 'alert',
        message: error.message,
        isShow: true
      })
    })
  }

  const boardInquiry = async (params: BoardInquiryProps) => {

    loader({isLoading: true})

    const formData = new FormData()
    formData.append('author', params.author)
    formData.append('email', params.email)
    formData.append('isSecrete', String(Number(params.isSecrete)))
    formData.append('password', params.password)
    formData.append('title', params.title)
    formData.append('content', params.content)
    formData.append('ask_by', String(params.ask_by))
    formData.append('files', params.files)

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/board/inquiry',
      params: formData
    }).then((res) => {
      loader({isLoading: false})
      return res.data
    }, (error) => {
      loader({isLoading: false})
      setAlert({
        title: error.name,
        type: 'alert',
        message: error.message,
        isShow: true
      })
    })
  }

  return {
    getBoardList,
    getBoardDetail,
    boardPassCheck,
    boardInquiry
  }
}
