import React from 'react'
import { OrderListWrap } from './styles'
import { OrderListProps } from './types'
import { useLocation } from 'react-router'

const OrderList = () => {

  const location = useLocation()

  const options = location?.state?.shopOptions

  const shopDetail = location?.state?.shopDetail

  const thumbnail = shopDetail?.thumbnail && JSON.parse(shopDetail?.thumbnail)[0].path

  return (
    <OrderListWrap>
      <ul>
        {
          options && options.filter((item: any) => item.cnt > 0).map((item: any, index: number) => {
            return (
              <li key={index}>
                <div 
                  className="figure"
                  style={{background: `url(${process.env.REACT_APP_BASE_URL}${thumbnail}) no-repeat center / cover`}}
                ></div>
                <div className="info-wrap">
                  <div className="info">
                    <div className="name">{item.name}</div>
                    <div className="price"><strong>{((item.original_price + item.tax) * item.cnt).toLocaleString('ko-KR')}</strong> 원</div>
                  </div>
                  <div className="count">
                    구매수량 : {item.cnt}
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    </OrderListWrap>
  )
}

export default OrderList