import {atom} from 'recoil'
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

interface NoticeList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    title: string
    order: number
    content: string
    open: number
    created_at: string
    createdAt: string
    updated_at: string
    updatedAt: string
    userId: number
  }[]
}

interface Notice {
  author?: number
  content: string
  created_at?: string
  id?: number
  order?: number
  title: string
  type?: string
  updated_at?: string
  userId?: number
  top_fix?: number
  open?: number
  prev?: number
  next?: number
}

export const tempIdAtom = atom({
  key: 'tempIdAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const noticeListAtom = atom<NoticeList>({
  key: 'noticeListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const noticeAtom = atom<Notice>({
  key: 'noticeAtom',
  default: {
    author: 0,
    content: "",
    created_at: "",
    id: 0,
    order: 0,
    title: "",
    top_fix: 0,
    type: "",
    updated_at: '',
    userId: 0,
    open: 0,
    prev: 0,
    next: 0
  }
})
