import {atom} from 'recoil'

interface AccountList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    type: string;
    email: string;
    bank: string;
    number: string;
    actname: string;
    price: number;
    method: string;
    createdAt: string;
    state: string;
  }[]
}

export const accountListAtom = atom<AccountList>({
  key: 'accountListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})
