import React, { useState } from 'react'
import DaumPostcode from 'react-daum-postcode';
import { ModalWrap } from './styles'
import { AddressProps } from './types';

const Address = ({modal, handleModal, handleChange}: AddressProps) => {

  const postCodeStyle = {
    width: '400px',
    height: '400px'
  };
  const onCompletePost = (data: any) => {
    handleChange(data.zonecode, data.address)
    handleModal(false)
  };

  return modal ? (
    <ModalWrap>
      <DaumPostcode
        style={postCodeStyle}
        onComplete={onCompletePost}
        autoClose={false}
      ></DaumPostcode>
      <div className="bg" onClick={() => handleModal(false)}></div>
    </ModalWrap>
  ) : null
}

export default Address