import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {userListAtom, userAtom, userPointHistoryAtom, userSellerMoneyHistoryAtom} from '../recoil/user'
import {productAtom} from '../recoil/product'

export const UserAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [userList, setUserList] = useRecoilState(userListAtom)
  const setUser = useSetRecoilState(userAtom)

  const getUserList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/user/getUserList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setUserList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const addAdmin = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/user/addAdmin`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateUser = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/user/update`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 수정되었습니다.',
          isShow: true
        })

        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateAdmin = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/user/updateAdmin`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 수정되었습니다.',
          isShow: true
        })

        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getUser = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/user/getUser`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const stopUser = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/user/stopUser',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = userList.user.filter(item => !params.userId.includes(item.id))
        setUserList({
          ...userList,
          user: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateUserPermission = (params: any) => {
    loader({isLoading: true})

    defaultService.handleService({
      method: 'post',
      endPoint: `/user/updateUserPermission`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = userList.user.map(item => {
          if (item.id === params.id) {
            return {
              ...item,
              [params.column]: params.status === 1 ? 0 : 1
            }
          }
          return item
        })

        setUserList({
          ...userList,
          user: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    addAdmin,
    updateUser,
    updateAdmin,
    getUserList,
    getUser,
    stopUser,
    updateUserPermission
  }
}
