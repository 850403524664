import React from 'react'
import { CheckBoxStyled } from './styles'
import { CheckBoxProps } from './types'

const CheckBox = ({id, checked, handleChange}: CheckBoxProps) => {
  return (
    <CheckBoxStyled 
      type='checkbox' 
      id={id}
      checked={checked}
      onChange={handleChange}
    />
  )
}

export default CheckBox