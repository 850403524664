import React, {useCallback, useState} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'

import {useRecoilValue} from 'recoil'
import {loaderAtom} from '../../../store/recoil/loader'
import {AuthAction} from '../../../store/action'

import styled from 'styled-components'
import {Base} from '../../layout/default/style/Base'
import {Common} from '../../layout/default/style/Common'

import InputField from '../../module/input'
import LoadingButton from '../../module/loadingButton'
import Alert from '../../module/alert'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: scroll;
`

const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 32rem;
  width: 40rem;
  margin: auto;
`

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-size: 1.3rem;
  font-weight: 300;
  color: #666666;
  margin: 2rem 0;

  .btn-join {
    display: flex;
    flex: 1;
    flex-grow: 0;
    place-self: flex-start;
    font-size: 1.4rem;
    font-weight: 400;
    background: #efebef;
    border: .1rem solid #efebef;
    color: #333333;
    padding: .4rem 1rem;
    border-radius: .5rem;
    margin-top: 1rem;
  }

  h4, h5 {
    font-size: 1.7rem;
    text-align: left;
  }

  .description {
    font-size: 1.5rem;
  }
`

const Logo = styled(Link)`
  background-image: url('../../../images/logo.png');
  background-position: left;
  background-size: 5rem;
  background-repeat: no-repeat;
  text-indent: -999999999rem;
  height: 5rem;
  width: 5rem;
  margin-bottom: 4rem;
`

const Form = styled.form`
  display: flex;
  flex: 1;
  align-self: stretch;

  fieldset {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
  }

  /*******************
  input component 스타일 재설정
  ********************/

  input[type=text],
  input[type=password] {
    border-radius: 3rem;
    padding-left: 2.2rem;

    ~ label {
      font-size: 1.4rem;
      left: 1.2rem;
    }
  }
`

const Option = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: .5rem;
`

const Forgot = styled.div`
  margin-top: 5rem;
  text-align: center;

  a {
    color: #666666;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
  }

  span.sep {
    display: inline-block;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
  }
`

const Copy = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 5rem;
`

const Index = () => {

  const loader = useRecoilValue(loaderAtom)
  const {authLogin} = AuthAction()

  const [state, setState] = useState<any>({
    email: '',
    password: '',
    submitted: false,
    rememberEmail: false
  })

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.type
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {email, password} = state
    setState({
      ...state,
      submitted: true
    })

    if (email && password) {
      authLogin(email, password)
    }
  }

  return (
    <>
      <Base/>
      <Common/>
      <Container>
        <Login>
          <Logo to="/">ZooToox</Logo>

          <Info>
            <h5 className="m-t-20">이메일을 잊으셨나요?</h5>
            {/*<span className="description">아이디 찾기로 이동하세요.</span>*/}
            {/*<Link to="/auth/find/pass" className="btn btn-join">*/}
            {/*  아이디 찾기*/}
            {/*</Link>*/}
          </Info>

          <Form name="form" onSubmit={handleSubmit}>
            <fieldset>
              <InputField
                useAnimation={true}
                type={'text'}
                id="phone"
                name="phone"
                numberType={'number'}
                placeholder="휴대폰 번호를 입력하세요"
                minLength={11}
                onChange={(e) => handleChange(e)}
                check={true}
              />

              <LoadingButton
                loading={loader.isLoading}
                onClick={() => handleSubmit}
              >
                이메일 찾기
              </LoadingButton>
            </fieldset>
          </Form>

          <Forgot>
            <Link to="/auth/find/email">이메일 찾기</Link>
            <span className="sep after"></span>
            <Link to="/auth/find/pass">비밀번호 찾기</Link>
            <span className="sep after"></span>
            <Link to="/auth/">로그인 하기</Link>
          </Forgot>

          {/*<Copy>*/}
          {/*  Copyright © {moment().format('YYYY')} ZooToox Co., Ltd. All Rights Reserved*/}
          {/*</Copy>*/}
        </Login>
        <Alert/>
      </Container>
    </>
  )
}

export default Index

