import {atom} from 'recoil'

interface QnaList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    title: string
    content: string
    createdAt: string
    updatedAt: string
    userId: number
  }[]
}

interface Qna {
  id?: number
  title: string
  content: string
  userId?: number,
  author?: string,
  answer?: string,
  answer_at: string
  isAnswered: number
}

export const qnaListAtom = atom<QnaList>({
  key: 'qnaListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const qnaAtom = atom<Qna>({
  key: 'qnaAtom',
  default: {
    id: 0,
    title: '',
    content: '',
    author: '',
    answer_at: '',
    isAnswered: 0
  }
})
