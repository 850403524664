import React, { useState } from 'react'
import { TabList } from './styles'

interface TabProps {
  tabList: any[]
  handleChange: (i: number) => void
}

const Tab = ({tabList, handleChange}: TabProps) => {

  const [activeTab, setActiveTab] = useState(0)

  const handleClick = (i: number) => {
    setActiveTab(i)
    handleChange(i)
  }

  return (
    <TabList>
      <ul>
        {
          tabList.map((tab, index) => {
            return (
              <li key={index}>
                <button 
                  type='button'
                  onClick={() => handleClick(index)}
                  className={index === activeTab ? 'active' : ''}
                >{tab}</button>
              </li>
            )
          })
        }
      </ul>
    </TabList>
  )
}

export default Tab  