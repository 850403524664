import React, { useEffect, useState } from 'react'
import { InputItem } from './styles'
import { InputProps } from './types';

const Input = ({handleFocus, handleBlur, borderNone, type, value, handleChange, disabled, borderDashed, handleKeyDown, readOnly, maxLength}: InputProps) => {

  return (
    <InputItem 
      className={borderDashed ? 'dashed-line' : ''}
      type={type ? type : 'text'} 
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={borderNone ? {border: 0} : {}}
      onChange={(e) => handleChange && handleChange(e.target.value)}
      disabled={disabled}
      onKeyDown={handleKeyDown && handleKeyDown}
      readOnly={readOnly}
      maxLength={maxLength}
    />
  )
}

export default Input