import {atom} from 'recoil'

interface AlertProps {
  isShow: boolean
  title?: string
  message?: string
  type?: string
  action?: any
}

export const alertAtom = atom<AlertProps>({
  key: 'alertAtom',
  default: {
    isShow: false,
    title: '',
    message: '',
    type: '',
    action: null
  }
})

