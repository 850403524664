import {createGlobalStyle} from "styled-components";

export const Variable = createGlobalStyle`
  :root{
    --color-border-basic: #efebef;
    --color-box: #fcfcfc;
    
    --color-primary: #0d6efd;
    --color-danger: #dc3545;
    
    --color-font-basic: #333333;
  }
`
