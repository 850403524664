import React from 'react'
import { ButtonItem, LinkButton } from './styles'
import { ButtonProps } from './types'

const Button = ({link, text, borderColor, backgroundColor, textColor, boxShadow, hoverColor, marginTop, icon, handleClick}: ButtonProps) => {
  return link ? (
    <LinkButton 
      to={link}
      style={{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        color: textColor,
        boxShadow: boxShadow ? boxShadow : 'unset',
        marginTop: marginTop ? marginTop : 0
      }}
      $hovercolor={hoverColor}
    >
      {
        icon && (
          <img src={icon} alt="icon" />
        )
      }
      {text}
    </LinkButton>
  ) : (
    <ButtonItem
      style={{
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        color: textColor,
        boxShadow: boxShadow ? boxShadow : 'unset',
        marginTop: marginTop ? marginTop : 0
      }}
      $hovercolor={hoverColor}
      onClick={handleClick}
    >
      {
        icon && (
          <img src={icon} alt="icon" />
        )
      }
      {text}
    </ButtonItem>
  )
}

export default Button