import React, {useState, useEffect} from 'react'
import InputField from '../../module/input'
import Category from './Category'
import Location from '../../module/location'
import Button from '../../module/button'
import {Style} from './style/Style'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {CategoryAction} from '../../../store/action'
import {loaderAtom} from '../../../store/recoil/loader'
import {alertAtom} from '../../../store/recoil/alert'
import {CategoryInterface} from '../../../layout/default/types'
import category from './Category'

const Index = (props: any) => {
  const setAlert = useSetRecoilState(alertAtom)
  const {regisCategory, getCategoryList} = CategoryAction()
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>([])
  const [state, setState] = useState<any>({
    name: null,
    url: null
  })

  useEffect(() => {
    getCategoryList({page: 0, size: 100})
      .then((res) => {
        setCategoryList(res.data)
      })
  }, [])

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const reset = () => {
    setState({
      ...state,
      name: null,
      url: null
    })
  }

  const handleSubmit = () => {
    const {name} = state
    if (!name) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: `제목을 입력하세요`,
        isShow: true
      })
      return
    }

    state.name &&
    regisCategory(state).then((data: any) => {
      setCategoryList((prevCategoryList: any) => [data, ...prevCategoryList])
      reset()
    })
  }

  useEffect(() => {
    return () => {
      // 나갈때..
    }
  })

  const dummy = [
    {
      id: 0,
      parent: 0,
      label: '카테고리',
    },
    {
      id: 1,
      parent: 0,
      label: '상품권',
    }
  ]

  return (
    <Style.Container>
      <Location location={{name: '카테고리 관리'}}/>
      <Style.Content>
        <Style.Section>
          <InputField
            useAnimation={true}
            type="text"
            id="name"
            name="name"
            title="카테고리 이름"
            placeholder="카테고리 이름"
            value={state.name ? state.name : ''}
            onChange={(e) => handleChange(e)}
          />

          {/*<InputField*/}
          {/*  useAnimation={true}*/}
          {/*  type="text"*/}
          {/*  id="url"*/}
          {/*  name="url"*/}
          {/*  title="카테고리 경로"*/}
          {/*  placeholder="카테고리 경로"*/}
          {/*  value={state.url ? state.url : ''}*/}
          {/*  onChange={(e) => handleChange(e)}*/}
          {/*/>*/}

          <Style.Action>
            <Button
              margin={'1rem 0 0 0'}
              padding={'1rem 4.4rem'}
              radius={'.5rem'}
              bgcolor={'#3f3b3f'}
              color={'#ffffff'}
              themecolor={'dark-gray'}
              disabled={state.name ? false : true}
              onClick={reset}
            >
              리셋
            </Button>

            <Button
              margin={'1rem 0 0 0'}
              padding={'1rem 4.4rem'}
              radius={'.5rem'}
              bgcolor={'#3f3b3f'}
              color={'#ffffff'}
              themecolor={'dark-gray'}
              disabled={state.name ? false : true}
              onClick={handleSubmit}
            >
              추가
            </Button>
          </Style.Action>
        </Style.Section>

        <Style.Category>
          {
            categoryList ?
              categoryList.map((item: any) => <Category
                key={item.id}
                item={item}
                level={0}
                deleteItem={(id: any) => {
                  const filteredData = categoryList.filter(item => item.id !== id)
                  setCategoryList(filteredData)
                }}
                updateItem={(data: any) => {
                  const filteredData = categoryList.filter(item => {
                    if(item.id === data.id) {
                      item.name = data.name
                      item.code = data.code
                    }
                    return item
                  })
                  setCategoryList(filteredData)
                }}
              />)
              : <Style.Nodata>등록된 카테고리가 없습니다.</Style.Nodata>
          }
        </Style.Category>
      </Style.Content>
    </Style.Container>
  )
}

export default Index
