import React from 'react'
import { ShopListWrap } from './styles'
import { ShopListProps } from './types'
import ShopItem from '../item'

const ShopList = ({list}: ShopListProps) => {
  return (
    <ShopListWrap>
      <ul>
        {
          list.length > 0 ? list.map((item, index) => {
            return (
              <ShopItem 
                key={index}
                item={item}
              />
            )
          }) : (
            <li className='empty'>등록된 상품이 없습니다.</li>
          )
        }
      </ul>
    </ShopListWrap>
  )
}

export default ShopList