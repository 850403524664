import {ICONS} from './icon'

/****************
 * Example

 <Svg icon='send' width={22} height={22} color="#666666" />

 * active 사용시

 <NavLink
 to="/path/to"
 children = {({ isActive }) => {
      return (
        <React.Fragment>
          <Svg
            icon = 'write'
            width = {30}
            height = {30}
            color={isActive ? 'red' : '#666666'}
            stroke={'#323232'}
            strokeWidth={2}
            strokeCap={'round'}
            strokeJoin={'round'}
          />
        </React.Fragment>
      )
    }}
 >
 </NavLink>
 *****************/

interface Props {
  width?: number,
  height?: number,
  icon: keyof typeof ICONS,
  color?: string,
  fill?: string,
  stroke?: string,
  strokeWidth?: number,
  strokeCap?: any,
  strokeJoin?: any
}

const Index = ({
                 icon,
                 width,
                 height,
                 color,
                 fill,
                 stroke,
                 strokeWidth,
                 strokeCap,
                 strokeJoin
               }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={ICONS[icon].viewBox}
    >
      <path
        d={ICONS[icon].path}
        fill={fill ? fill : ''}
        color={color ? color : ''}
        stroke={stroke ? stroke : ''}
        strokeWidth={strokeWidth ? strokeWidth : ''}
        strokeLinecap={strokeCap ? strokeCap : ''}
        strokeLinejoin={strokeJoin ? strokeJoin : ''}
      />
    </svg>
  )
}

export default Index
