import styled from "styled-components";

export const ShopListWrap = styled.div`
  width: 100%;
  height: auto;
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    .empty {
      width: 100%;
      height: auto;
      text-align: center;
      padding: 3rem 0;
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
`