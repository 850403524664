import React, { useEffect, useState } from 'react'
import { BoardWrap } from './styles'
import { BoardProps } from './types'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Pagination from '../pagination';
import Search from '../search';
import moment from 'moment';

const Board = ({list, link, totalPage, size, searchEvent}: BoardProps) => {

  // navigate
  const navigate = useNavigate()

  // location
  const location = useLocation()
  const currentPage = new URLSearchParams(location.search).get('page') || 0

  // pagination
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPages: 0
  })

  const handleChangePage = (i: number) => {
    navigate(`${link}?page=${i}`)
  }

  // 변경감지
  useEffect(() => {
    setPagination({
      currentPage: Number(currentPage),
      totalPages: totalPage
    })
  }, [currentPage, totalPage])

  return (
    <BoardWrap>
      <ul className='boad-list'>
        <li className='hd'>
          <div className="no">no</div>
          <div className="title">제목</div>
          <div className="name">작성자</div>
          <div className="date">작성일자</div>
        </li>
        {
          list.length > 0 ? list.map((item, index) => {
            return (
              <li key={index}>
                <Link to={`${link}/detail/${item.id}`} state={{ isSecrete: item.isSecrete}}>
                  <div className="no">{(Number(location.search.split('=')[1]) * 10) + (index + 1)}</div>
                  <div className="title">
                    {item.title}
                    {item.isSecrete === 1 && <img src="/image/secrete-icon.png" alt="비밀" />}
                  </div>
                  <div className="name">
                    {item.author === 1 ? '관리자' : item.author}
                  </div>
                  <div className="date">{moment(item.created_at).format('YYYY-MM-DD')}</div>
                </Link>
              </li>
            )
          }) : (
            <li className='empty'>
              <span>등록된 게시글이 없습니다.</span>
            </li>
          )
        }
      </ul>
      {
        list.length > 0 && (
          <Pagination
            currentPage={pagination.currentPage} 
            totalPages={pagination.totalPages}
            onPageChange={(i) => handleChangePage(i)}
            size={size}
          />
        )
      }
      <Search 
        searchEvent={searchEvent}
        link={link}
      />
    </BoardWrap>
  )
}

export default Board