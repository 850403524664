import styled from "styled-components";

export const CardList = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    li {
      width: calc(100% / 6);
      height: auto;
      padding: 0 1rem;
      &:nth-child(n + 7) {
        margin-top: 2rem;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 2.4rem;
        border: 0.1rem solid #dfdfdf;
        border-radius: 1rem;
        .figure {
          width: 100%;
          padding-top: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 1240px) {
    ul {
      li {
        width: 25%;
        &:nth-child(n + 5) {
          margin-top: 2rem;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    ul {
      li {
        width: calc(100% / 3);
        &:nth-child(n + 4) {
          margin-top: 2rem;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    ul {
      li {
        width: 50%;
        &:nth-child(n + 3) {
          margin-top: 2rem;
        }
      }
    }
  }
`