import React from 'react'
import styled, {css} from 'styled-components'

/******************************
 - 사용법

 <Button
 size={'sm | md | lg'}
 margin={'1rem 0'}
 padding={'1rem 0'}
 radius={'.8rem'}
 bgcolor={'#3f3b3f'}
 color={'#ffffff'}
 fontSize={'1.4rem'}
 themecolor={'purple | blue | light-gray dark-gray |  gray | red |  white | black'}
 disabled={false}
 onClick={'actions()'}
 >
 버튼명
 </Button>
 ******************************/

interface ButtonStyleProps {
  size?: string
  $grow?: boolean
  $margin?: string
  $padding?: string
  radius?: string
  $bgcolor?: string
  color?: string
  fontSize?: string
  $themecolor?: string
}

const Button = styled.button<ButtonStyleProps>`
  display: inline-flex;
  flex-grow: ${({$grow}) => ($grow ? 1 : 0)};
  align-items: center;
  justify-content: center;
  user-select: none;
  background-image: none;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  font-weight: normal;

  &:disabled,
  button[disabled] {
    background-color: #cccccc !important;
    border: .1rem solid #cccccc !important;
    color: #ffffff !important;
  }

  border-radius: ${({radius}) => (radius ? radius : '.8rem')};
  background-color: ${({$bgcolor}) => ($bgcolor ? $bgcolor : '#555555')};
  border: ${({$bgcolor}) => ($bgcolor ? `'.1rem solid ${$bgcolor}'` : '.1rem solid #555555')};
  margin: ${({$margin}) => ($margin ? $margin : '2rem 0')};
  color: ${({color}) => (color ? color : '#ffffff')};
  
  ${({size}) => size === 'sm' && css`
    padding: .6rem 1.2rem;
    font-size: 1.2rem;
  `}

  ${({size}) => size === 'md' && css`
    padding: .8rem 1.6rem;
    font-size: 1.4rem;
  `}

  ${({size}) => size === 'lg' && css`
    padding: 1.2rem 2rem;
    font-size: 1.4rem;
  `}

  ${({$themecolor}) => $themecolor === 'purple' && css`
    background-color: #8878FB;
    border: .1rem solid #8878FB;
    color: #FFFFFF;
  `}

  ${({$themecolor}) => $themecolor === 'blue' && css`
    background-color: #2285c8;
    border: .1rem solid #2285c8;
    color: #FFFFFF;
  `}

  ${({$themecolor}) => $themecolor === 'light-gray' && css`
    background-color: #D8D8D8;
    border: .1rem solid #D8D8D8;
    color: #ffffff;
  `}

  ${({$themecolor}) => $themecolor === 'dark-gray' && css`
    background-color: #555555;
    border: .1rem solid #555555;
    color: #ffffff;
  `}

  ${({$themecolor}) => $themecolor === 'gray' && css`
    background-color: #efefef;
    border: .1rem solid #efefef;
    color: #111111;

    &:hover {
      background-color: #e3e3e3;
    }
  `}

  ${({$themecolor}) => $themecolor === 'red' && css`
    background-color: #C00000;
    border: .1rem solid #C00000;
    color: #FFFFFF;
  `}

  ${({$themecolor}) => $themecolor === 'white' && css`
    background-color: #FFFFFF;
    border: .1rem solid #CCCCCC;
    color: #7b7d89;
  `}

  ${({$themecolor}) => $themecolor === 'black' && css`
    background-color: #333333;
    border: .1rem solid #333333;
    color: #FFFFFF;
  `}

  padding: ${({$padding}) => ($padding ? $padding : '.8rem 1rem')};
  font-size: ${({fontSize}) => (fontSize ? fontSize : '1.4rem')};
`

interface ButtonProps {
  children?: React.ReactNode
  size?: string
  grow?: boolean
  margin?: string
  padding?: string
  radius?: string
  bgcolor?: string
  color?: string
  fontSize?: string
  themecolor?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Index = ({
                 children,
                 size,
                 grow,
                 margin,
                 padding,
                 radius,
                 bgcolor,
                 color,
                 fontSize,
                 themecolor,
                 disabled,
                 onClick,
               }: ButtonProps) => {
  return (
    <Button
      size={size}
      $grow={grow}
      $margin={margin}
      $padding={padding}
      radius={radius}
      $bgcolor={bgcolor}
      color={color}
      fontSize={fontSize}
      $themecolor={themecolor}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default Index
