import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {productListAtom, productAtom} from '../recoil/product'
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export const ProductAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [productList, setProductList] = useRecoilState(productListAtom)
  const setProduct = useSetRecoilState(productAtom)

  const getProductList = async (params: any) => {
    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/order/orderList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  // 기프티콘 등록
  const cancelOrder = async (params: any) => {
    return await defaultService.handleService({
      method: 'post',
      endPoint: '/order/cancelOrder',
      params: params
    }).then(
      response => {
        return response.data.payload
      }
    )
  }

  return {
    getProductList,
    cancelOrder
  }
}
