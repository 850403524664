import styled from "styled-components";

export const ShopWrap = styled.div`
  padding: 11rem 0;
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 0;
  }
`

export const ShopDetailWrap = styled.div`
  padding: 11rem 0;
  .shop-info {
    display: flex;
    gap: 5rem;
    > div {
      flex: 1;
    }
    .figure {
      span {
        display: block;
        padding-bottom: 100%;
      }
    }
    .mobile-title {
      display: none;
    }
    .info-wrap {
      .title {
        font-size: 2.8rem;
        font-weight: 500;
        color: #b868bf;
        margin-bottom: 1.5rem;
      }
      .option {
        border-top: 0.1rem solid #dfdfdf;
        dl {
          height: 6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.1rem solid #dfdfdf;
          dt {
            font-size: 1.6rem;
            font-weight: 400;
          }
          dd {
            font-size: 1.6rem;
            font-weight: 400;
          }
        }
      }
      .total-price {
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.1rem solid #dfdfdf;
        span {
          font-size: 1.6rem;
          font-weight: 700;
        }
      }
      .btn-wrap {
        margin-top: 1.5rem;
      }
    }
  }
  .tab-wrap {
    padding: 4rem 0;
  }
  .contents {
    text-align: center;
  }
  .policy-wrap {
    h5 {
      font-size: 1.8rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 3rem;
    }
    .info-wrap {
      ul {
        border: 0.1rem solid #dfdfdf;
        li {
          display: flex;
          &:not(:last-child) {
            border-bottom: 0.1rem solid #dfdfdf;
          }
          .title {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eee;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.6rem;
            line-height: 1.5;
            font-weight: 400;
            color: rgba(0,0,0,.87);
          }
          .text {
            width: 65%;
            padding: 1.5rem;
            font-size: 1.6rem;
            line-height: 1.5;
            font-weight: 400;
            color: rgba(0,0,0,.87);
            span {
              font-weight: 700;
              color: rgb(34, 41, 139);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1048px) {
    .shop-info {
      display: block;
      .info-wrap {
        margin-top: 4rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
    .shop-info {
      .mobile-title {
        display: block;
        font-size: 2.6rem;
        font-weight: 500;
        color: #b868bf;
        margin-top: 4rem;
      }
      .info-wrap {
        width: 100%;
        height: auto;
        margin-top: 0;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 9;
        background-color: #fff;
        box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);
        .title {
          display: none;
        }
        .option {
          border-top: 0;
          padding: 1.5rem;
          overflow-y: auto;
          max-height: 15rem;
          dl {
            border-bottom: 0;
          }
        }
        .total-price {
          border-top: 0.1rem solid #dfdfdf;
          padding: 0 1.5rem;
        }
        .btn-wrap {
          margin-top: 0;
          padding: 1.5rem;
        }
      }
    }
    .policy-wrap {
      .info-wrap {
        ul {
          border: 0;
          li {
            display: block;
            &:not(:last-child) {
              border-bottom: 0;
            }
            .title {
              width: 100%;
            }
            .text { 
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 0;
    .shop-info {
      .mobile-title {
        display: block;
        font-size: 2.4rem;
      }
      .info-wrap {
        .option {
          max-height: 13rem;
          dl {
            height: 5rem;
            dt {
              font-size: 1.4rem;
            }
          }
        }
        .total-price {
          span {
            font-size: 1.4rem;
          }
        }
        .btn-wrap {
          margin-top: 0;
          padding: 1.5rem;
        }
      }
    }
    .policy-wrap {
      h5 {
        font-size: 1.6rem;
      }
      .info-wrap {
        ul {
          li {
            .title {
              font-size: 1.4rem;
            }
            .text { 
              font-size: 1.4rem;
              word-break: keep-all;
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`

export const TitleWrap = styled.div`
  padding-left: 3.2rem;
  margin-bottom: 4.8rem;
  h2 {
    font-size: 2.8rem;
    font-weight: 500;
    color: #222;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 4.5rem;
    h2 {
      text-align: center;
      font-size: 2.6rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 4rem;
    h2 {
      font-size: 2.4rem;
    }
  }
`
