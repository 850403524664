import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonStyledInterface } from "./types";

export const ButtonItem = styled.button<ButtonStyledInterface>`
  display: block;
  width: 100%;
  height: 5rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  line-height: 5rem;
  font-weight: 500;
  border-width: 0.1rem;
  border-style: solid;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${(props) => props.$hovercolor ? props.$hovercolor : 'transparent'};
    opacity: 0;
    transition: all ease 0.3s;
  }
  &:hover {
    &::after {
      opacity: 0.08;
    }
  }
  img {
    width: 2.4rem;
    margin-right: 1rem;
    padding-bottom: 0.3rem;
  }
  @media screen and (max-width: 500px) {
    height: 4rem;
    line-height: 4rem;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    img {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
`

export const LinkButton = styled(Link)<ButtonStyledInterface>`
  display: block;
  width: 100%;
  height: 5rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  line-height: 4.8rem;
  font-weight: 500;
  text-align: center;
  border-width: 0.1rem;
  border-style: solid;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${(props) => props.$hovercolor ? props.$hovercolor : 'transparent'};
    opacity: 0;
    transition: all ease 0.3s;
  }
  &:hover {
    &::after {
      opacity: 0.08;
    }
  }
  img {
    width: 2.4rem;
    margin-right: 1rem;
    padding-bottom: 0.3rem;
  }
  @media screen and (max-width: 500px) {
    height: 4rem;
    line-height: 4rem;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    img {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
`