import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {policyListAtom, policyAtom} from '../recoil/policy'

export const PolicyAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [policyList, setPolicyList] = useRecoilState(policyListAtom)
  const setPolicy = useSetRecoilState(policyAtom)

  const regisPolicy = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/policy/insert/',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 등록되었습니다.',
          isShow: true
        })
        !params.id && navigate(-1)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updatePolicy = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/policy/update',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate(-1)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPolicyList = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/policy/list`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPolicyList(response.data.payload)
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPolicy = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/policy/detail/`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPolicy(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deletePolicy = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/policy/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        const filteredList = policyList.data.filter(item => !params.ids.includes(item.id))
        setPolicyList({
          ...policyList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regisPolicy,
    updatePolicy,
    getPolicyList,
    getPolicy,
    deletePolicy
  }
}
