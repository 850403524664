import React, { useEffect, useState } from 'react'
import { NoticeWrap, TitleWrap } from './styles'
import Board from '../../components/board'
import { NoticeAction } from '../../store/action'
import { useLocation } from 'react-router'
import { useRecoilValue } from 'recoil'
import { noticeListAtom } from '../../store/recoil/notice'

const Notice = () => {

  // location
  const location = useLocation()
  const currentPage = new URLSearchParams(location.search).get('page') || 0

  // Notice Action
  const { getNoticeList } = NoticeAction()

  // Search
  const [search, setSearch] = useState({
    keyword: '',
    searchType: ''
  })

  // 리스트 가져오기
  const handleGetNoticeList = (keyword: string, searchType: string) => {
    setSearch({
      keyword: keyword,
      searchType: searchType
    })
    getNoticeList({
      page: Number(currentPage),
      size: 10,
      type: 'notice',
      searchType: searchType,
      keyword: keyword
    })
  }


  // 공지사항 리스트 가져오기
  useEffect(() => {
    handleGetNoticeList(search.keyword, search.searchType)
  }, [currentPage])

  // 공지사항 리스트
  const noticeList = useRecoilValue(noticeListAtom)

  return (
    <NoticeWrap>
      <div className='container'>
        <TitleWrap>
          <h2>공지사항</h2>
        </TitleWrap>
        <Board 
          list={noticeList.data}
          link={'/notice'}
          totalPage={noticeList.pageInfo.total}
          size={noticeList.pageInfo.size}
          searchEvent={handleGetNoticeList}
        />
      </div>
    </NoticeWrap>
  )
}

export default Notice