import {atom} from 'recoil'

interface BoardInterface {
  title: string
  id: number
  ask_at: string
  author: string
  email: string
  isSecrete: number
  content: string
  answer_by: Date | string
  answer_at: Date | string
  answer: string
  isAnswered: number
  password: string
  files: any
  count: number
}

interface BoardListInterface {
  data: BoardInterface[]
  pageInfo: {
    page: number
    size: number
    total: number
  }
}

export const boardListAtom = atom<BoardListInterface>({
  key: 'boardListAtom',
  default: {
    data: [],
    pageInfo: {
      total: 0,
      page: 0,
      size: 0
    }
  }
})

export const boardAtom = atom<BoardInterface>({
  key: 'boardAtom',
  default: {
    title: '',
    id: 0,
    ask_at: '',
    author: '',
    email: '',
    isSecrete: 0,
    content: '',
    answer_by: '',
    answer_at: '',
    answer: '',
    isAnswered: 0,
    password: '',
    files: '',
    count: 0
  }
})
