import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './default/Header'
import Footer from './default/Footer'
import styled from 'styled-components'
import SideMenu from './default/SideMenu'
import Alert from '../module/alert'
import MobileHeader from './mobile/header'
import MobileSideMenu from './mobile/SideMenu'

const Wrap = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100vh - 44.75rem);
  .container-mypage {
    display: flex;
    max-width: 120rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    gap: 3rem;
    padding: 4rem 0;
  }
  @media screen and (max-width: 1240px) {
    min-height: calc(100vh - 36.65rem);
    .container-mypage {
      display: block;
      padding: 4rem 1.5rem;
    }
  }
  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 45.65rem);
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 41.45rem);
    .container-mypage {
      display: block;
      padding: 8rem 1.5rem;
    }
  }
  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 33.85rem);
    .container-mypage {
      display: block;
      padding: 6rem 1.5rem;
    }
  }
`

const DefaultLayout = () => {

  // Location
  const location = useLocation()

  // Mobile Menu
  const [menuStatus, setMenuStatus] = useState(false)
  const handleMenuClick = () => {
    setMenuStatus(!menuStatus)
  }

  useEffect(() => {
    setMenuStatus(false)
  }, [location])

  return (
    <>
      <Header/>
      <MobileHeader 
        handleMenuClick={handleMenuClick}
      />
      <MobileSideMenu 
        menuStatus={menuStatus}
        handleMenuClick={handleMenuClick}
      />
      <Wrap>
        {
          location.pathname.includes('/my') ? (
            <div className="container-mypage">
              <SideMenu />
              <Outlet/>
            </div>
          ) : (
            <Outlet/>
          )
        }
      </Wrap>
      <Footer/>
      <Alert/>
    </>
  )
}

export default DefaultLayout
