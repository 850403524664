import React, { useEffect, useState } from 'react'
import { CountWrap } from './styles'
import { CountProps } from './types'

const Count = ({value, increaseFlag, maxCount, handleChangeCount}: CountProps) => {

  const [count, setCount] = useState(0)
  const handleChange = (i: number) => {
    const newValue = Math.min(i, maxCount); // Ensure newValue doesn't exceed maxCount
    setCount(newValue);
  }

  const handleMinus = () => {
    count > 0 && setCount(count - 1)
  }

  const handlePlus = () => {
    increaseFlag && count < maxCount && setCount(count + 1)
  }


  useEffect(() => {
    handleChangeCount(count)
  }, [count])

  useEffect(() => {
    if(!increaseFlag) setCount(value)
  }, [increaseFlag])

  return (
    <CountWrap>
      <button
        type='button'
        onClick={handleMinus}
      >
        -
      </button>
      <input 
        type="number" 
        value={count}
        onChange={(e) => handleChange(Number(e.target.value))}
        max={maxCount} 
      />
      <button
        type='button'
        onClick={handlePlus}
      >
        +
      </button>
    </CountWrap>
  )
}

export default Count
