import React, { useEffect } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authAtom } from '../store/recoil/auth'
import { alertAtom } from '../store/recoil/alert'
import ScrollToTop from './ScrollToTop'

/*************
 Auth
 *************/

import AuthRoute from './AuthRoute'

/*************
 layout
 *************/

import AdminLayout from '../admin/layout/DefaultLayout'
import DefaultLayout from '../layout/DefaultLayout'

/*************
 admin
 *************/

import AdminAuth from '../admin/page/auth/'
import AdminUser from '../admin/page/user/'
import AdminNotice from '../admin/page/notice/'
import AdminFaq from '../admin/page/faq/'
import AdminQna from '../admin/page/qna/'
import AdminPolicy from '../admin/page/policy/'
import AdminPopUp from '../admin/page/popup/'
import AdminBanner from '../admin/page/banner/'
import AdminName from '../admin/page/name/'
import AdminRefund from '../admin/page/refund/'
import AdminCategory from '../admin/page/category/'
import AdminGift from '../admin/page/gift/'
import AdminProduct from '../admin/page/product/'

/*************
 public
 *************/

import Home from '../page/home'
import Login from '../page/login'
import FindUser from '../page/findUser'
import About from '../page/about'
import Join from '../page/join'
import Notice from '../page/notice'
import NoticeDetail from '../page/notice/Detail'
import Inquiry from '../page/inquiry'
import InquiryDetail from '../page/inquiry/Detail'
import InquiryWrite from '../page/inquiry/Write'
import Contact from '../page/contact'
import Shop from '../page/shop'
import ShopDetail from '../page/shop/Detail'
import Order from '../page/order'
import Terms from '../page/terms'
import Policy from '../page/policy'
import MyOrder from '../page/my/order'
import MyProfile from '../page/my/profile'

import Error from '../page/error'


const Routers = () => {

  const adminPublicRoutes = [
    {name: 'Auth', path: '/admin/auth', element: AdminAuth}
  ]

  const adminRoutes = [
    {name: 'Admin', path: '/admin', element: AdminProduct},
    {name: 'User', path: '/admin/user/*', element: AdminUser},
    {name: 'Notice', path: '/admin/notice/*', element: AdminNotice},
    {name: 'Faq', path: '/admin/faq/*', element: AdminFaq},
    {name: 'Qna', path: '/admin/qna/*', element: AdminQna},
    {name: 'Policy', path: '/admin/policy/*', element: AdminPolicy},
    {name: 'PopUp', path: '/admin/popup/*', element: AdminPopUp},
    {name: 'Banner', path: '/admin/banner/*', element: AdminBanner},
    {name: 'Name', path: '/admin/name/*', element: AdminName},
    {name: 'Refund', path: '/admin/refund/*', element: AdminRefund},
    {name: 'Category', path: '/admin/category/*', element: AdminCategory},
    {name: 'Gift', path: '/admin/gift/*', element: AdminGift},
    {name: 'Product', path: '/admin/product/*', element: AdminProduct}
  ]

  const publicRoutes = [
    {name: 'Home', path: '/', element: Home},
    {name: 'Login', path: '/login', element: Login},
    {name: 'FindUser', path: '/find/user', element: FindUser},
    {name: 'Join', path: '/join', element: Join},
    {name: 'About', path: '/about', element: About},
    {name: 'Notice', path: '/notice', element: Notice},
    {name: 'NoticeDetail', path: '/notice/detail/*', element: NoticeDetail},
    {name: 'Inquiry', path: '/inquiry', element: Inquiry},
    {name: 'InquiryDetail', path: '/inquiry/detail/*', element: InquiryDetail},
    {name: 'InquiryWrite', path: '/inquiry/write', element: InquiryWrite},
    {name: 'Contact', path: '/contact', element: Contact},
    {name: 'Shop', path: '/shop', element: Shop},
    {name: 'ShopDetail', path: '/shop/detail/*', element: ShopDetail},
    {name: 'Order', path: '/order', element: Order},
    {name: 'Terms', path: '/terms', element: Terms},
    {name: 'Policy', path: '/policy', element: Policy},
    {name: 'MyOrder', path: '/my/order', element: MyOrder},
    {name: 'MyProfile', path: '/my/profile', element: MyProfile},
    {name: 'Error', path: '/*', element: Error}
  ]

  const auth = useRecoilValue(authAtom)

  const location = useLocation()

  const navigate = useNavigate()

  const setAlert = useSetRecoilState(alertAtom)

  useEffect(() => {
    if (location.pathname.includes('admin') && auth.role !== 0) {
      // navigate('/')
      // setAlert({
      //   title: '접근제한',
      //   type: 'alert',
      //   message: '관리자만 접근할 수 있습니다.',
      //   isShow: true
      // })
      // console.log('관리자가 아닙니다.')
    }
  }, [location])

  return (
    <>
      <ScrollToTop/>

      <Routes>
        <Route
          path="/"
          element={<DefaultLayout/>}
        >
          {publicRoutes.map((route, key) => (
            <Route
              key={key}
              path={route.path}
              element={<route.element/>}
            />
          ))}
        </Route>

        {adminPublicRoutes.map((route, key) => (
          <Route
            key={key}
            path={route.path}
            element={<route.element/>}
          />
        ))}

        <Route
          path="/admin" element={
          <AuthRoute auth={auth}>
            <AdminLayout />
          </AuthRoute>
        }
        >
          {adminRoutes.map((route, key) => (
            <Route
              key={key}
              path={route.path}
              element={<route.element/>}
            />
          ))}
        </Route>

      </Routes>
      <div id={'popupRoot'} />
    </>
  )
}

export default Routers
