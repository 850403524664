import React from 'react'
import { CardList } from './styles'
import { Link } from 'react-router-dom'

interface CardProps {
  list: any[]
}

const Card = ({list}: CardProps) => {
  return (
    <CardList>
      <ul>
        {
          list.map((item, index) => {
            return (
              <li key={index}>
                <Link to={'/'}>
                  <div 
                    className='figure'
                    style={{background: `url('${item.image}') no-repeat center / contain`}}
                  ></div>
                </Link>
              </li>
            )
          })
        }
      </ul>
    </CardList>
  )
}

export default Card