import styled from "styled-components";

export const OrderHistoryWrap = styled.div`
  overflow-x: auto;
  .boad-list {
    width: 100%;
    height: auto;
    border-top: 0.1rem solid #dfdfdf;
    li {
      width: 100%;
      height: 4.8rem;
      border-bottom: 0.1rem solid #dfdfdf;
      display: flex;
      align-items: center;
      > div {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87); 
      }
      &.hd {
        > div {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .order-id {
        width: 30%;
      }
      .title {
        width: 20%;
      }
      .price {
        width: 10%;
      }
      .issue-status {
        width: 10%;
      }
      .payment-status {
        width: 10%;
      }
      .date {
        width: 20%;
      }
      .empty {
        width: 100%;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 910px) {
    .boad-list {
      width: 91rem;
    }
  }
`

export const OrderListWrap = styled.div`
  ul {
    border-top: 0.1rem solid #dfdfdf;
    li {
      display: flex;
      align-items: center;
      padding: 1.5rem;
      border-bottom: 0.1rem solid #dfdfdf;
      .figure {
        width: 13rem;
        height: 13rem;
      }
      .info-wrap {
        width: calc(100% - 13rem);
        height: auto;
        padding-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          .name {
            font-size: 1.6rem;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
          }
          .price {
            margin-top: 1rem;
            font-size: 1.6rem;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            strong {  
              font-weight: 700;
            }
          }
        }
        .count {
          font-size: 1.4rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    ul {
      li {
        align-items: unset;
        .info-wrap {
          flex-direction: column;
          align-items: start;
          padding: 1.5rem 0;
        }
      }
    } 
  }
  @media screen and (max-width: 500px) {
    ul {
      li {
        display: block;
        .figure {
          margin: 0 auto;
        }
        .info-wrap {
          padding: 0;
          margin-top: 2rem;
          .info {
            .name {
              font-size: 1.4rem;
            }
            .price {
              font-size: 1.4rem;
            }
          }
          .count {
            margin-top: 1rem;
          }
        }
      }
    } 
  }
`