export const bannerList = [
  {
    path: '/image/banner-img1.jpeg'
  },
  {
    path: '/image/banner-img2.jpeg'
  }
]

export const sliderList = [
  {
    id: 1,
    image: '/image/slider-img.jpeg',
    title: 'e-biz GIFT 5만원권',
    text: '권종 선택구매'
  },
  {
    id: 2,
    image: '/image/slider-img.jpeg',
    title: 'e-biz GIFT 5만원권',
    text: '권종 선택구매'
  },
  {
    id: 3,
    image: '/image/slider-img.jpeg',
    title: 'e-biz GIFT 5만원권',
    text: '권종 선택구매'
  },
  {
    id: 4,
    image: '/image/slider-img.jpeg',
    title: 'e-biz GIFT 5만원권',
    text: '권종 선택구매'
  },
  {
    id: 5,
    image: '/image/slider-img.jpeg',
    title: 'e-biz GIFT 5만원권',
    text: '권종 선택구매'
  },
]

export const tabList = ['전체', '상품권', '콘텐츠', '외식', '편의생활', 'E-BIZ GIFT']

export const cardList = [
  {
    image: '/image/gnb-google.png'
  }
]

export const noticeList = [
  {
    id: 1,
    title: '쿠폰 발권 정책 변경 안내',
    date: '2022-12-06'
  },
  {
    id: 2,
    title: '페이스토리상품권 쇼핑몰 오픈',
    date: '2022-12-06'
  },
]
