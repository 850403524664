import React from 'react'
import styled, {css} from 'styled-components'

const LoadingButton = styled.button<{$loading?: boolean}>`
  border: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #e0e0e0;
  width: 100%;
  height: 5.2rem;
  margin-top: 1.5rem;
  border-radius: 2.5rem;
  background: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    top: 0;
    left: 0;
    background: #005bea;
    background: -webkit-linear-gradient(to right, #005bea, #00c6fb);
    background: -o-linear-gradient(to right, #005bea, #00c6fb);
    background: linear-gradient(to right, #005bea, #00c6fb);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    opacity: 0;
  }

  &:hover {
    background: transparent;
    color: #fff;

    &:before {
      opacity: 1;
    }
  }

  ${({$loading}) => $loading === true && css`
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spiner 500ms infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      line-height: 0;
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2) - .2rem);
      top: calc(50% - (1em / 2) - .2rem);
    }

    svg,
    img {
      opacity: 0;
    }

    @keyframes spiner {
      to {
        transform: rotate(360deg);
      }
    }
  `}
`

interface LoadingButtonProps {
  loading?: boolean
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Index = ({loading, children, onClick, ...props}: LoadingButtonProps) => {
  return (
    <LoadingButton
      onClick={() => onClick}
      $loading={loading}
    >
      {children}
    </LoadingButton>
  )
}

export default Index
