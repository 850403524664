import React, { useState } from 'react'
import { PasswordCheckWrap } from './styles'
import { PasswordCheckProps } from './types'

const PasswordCheck = ({handleCheckPass}: PasswordCheckProps) => {

  const [password, setPassword] = useState('')

  return (
    <PasswordCheckWrap>
      <div className="box">
        <h4>
          게시글 열람을 위해<br/>
          비밀번호를 입력해주세요.
        </h4>
        <div className="input-wrap">
          <input 
            type="password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button 
            type='button'
            onClick={() => handleCheckPass(password)}
          >
            확인
          </button>
        </div>
      </div>
    </PasswordCheckWrap>
  )
}

export default PasswordCheck