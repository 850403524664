import axios from 'axios'

const headers = {
  Authorization: process.env.REACT_APP_WGP_API_KEY,
  "Content-Type": 'application/json'
}

const WingService = {
  echo: async () => {
    try {
      const response = await axios.post(
        'https://api.winglobalpay.com/api/echo',
        {},
        { headers }
      );
      return response.data.result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  pay: async (payload) => {
    try {
      const response = await axios.post(
        'https://api.winglobalpay.com/api/pay',
        { ...payload },
        { headers }
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};

export default WingService;
