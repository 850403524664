import {atom} from 'recoil'

interface FaqList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    title: string
    order: number
    content: string
    open: number
    createdAt: string
    updatedAt: string
    userId: number
  }[]
}

interface Faq {
  id?: number
  title: string
  content: string
  order?: number
  userId?: number
  open?: number
}

export const faqListAtom = atom<FaqList>({
  key: 'faqListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const faqAtom = atom<Faq>({
  key: 'faqAtom',
  default: {
    id: 0,
    title: '',
    content: '',
    order: 0,
    userId: 0,
    open: 0
  }
})
