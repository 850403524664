import {atom} from 'recoil'

interface GiftList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    name: string;
    content: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    userId: number;
  }[]
}

interface Gift {
  stat: [],
  options: [],
  product: [{
    id: number;
    categories: any
    code: string;
    name: string;
    content: string;
    created_at: string;
    updated_at: string;
    required: boolean
    soldout: any
    display: any
    display_order: any
    thumbnail: any
    images: any
    grade: any
  }]
}

export const giftListAtom = atom<GiftList>({
  key: 'giftListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const giftAtom = atom<Gift>({
  key: 'giftAtom',
  default: {
    stat: [],
    options: [],
    product: [{
      id: 0,
      categories: '',
      code: '',
      name: '',
      required: false,
      content: '',
      created_at: '',
      updated_at: '',
      soldout: '',
      display: '',
      display_order: '0',
      thumbnail: '',
      images: '',
      grade: '0',
    }]
  }
})
