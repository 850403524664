import React, { useEffect, useState } from 'react'
import { PopupWrap } from './styles'
import { PopupProps } from './types'
import { Link } from 'react-router-dom'

const Popup = ({item}: PopupProps) => {

  const [popupStatus, setPopupStatus] = useState(true)

  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    const lastShownDate = localStorage.getItem(`lastShownDate${item.id}`)
    if (lastShownDate === getCurrentDate()) {
      setPopupStatus(false)
    } else {
      setPopupStatus(true)
    }
  }, [])

  const getCurrentDate = () => {
    const today = new Date()
    return today.toISOString().split('T')[0]
  }

  const handleCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    localStorage.setItem(`lastShownDate${item.id}`, checked ? getCurrentDate() : '')
    setIsChecked(checked)
  };

  const handleCloseButtonClick = () => {
    setPopupStatus(false)
  }

  return popupStatus ? (
    <PopupWrap style={{top: item.x, left: item.y}}>
      <div 
        className='contents'
        style={{width: item.width, height: item.height}}
      >
        <Link to={item.url}>
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
        </Link>
      </div>
      <div className='info-wrap'>
        <label htmlFor={`close-check-${item.id}`}>
          <input 
            type="checkbox" 
            id={`close-check-${item.id}`} 
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span>오늘 하루 열지 않음</span>
        </label>
        <button 
          type="button"
          onClick={handleCloseButtonClick}
        >닫기</button>
      </div>
    </PopupWrap>
  ) : null
}

export default Popup