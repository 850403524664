import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import { guideListAtom } from '../recoil/guide'

export const GuideAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setGuideList = useSetRecoilState(guideListAtom)

  const getGuideList = async () => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/guide/list',
      params: {}
    }).then((res) => {
      loader({isLoading: false})
      setGuideList(res.data.data)
      return res.data.data
    }, (error) => {
      loader({isLoading: false})
      setAlert({
        title: error.name,
        type: 'alert',
        message: error.message,
        isShow: true
      })
    })
  }

  const guideSave = async (params: any) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/guide/save',
      params: {
        rows: params
      }
    }).then((res) => {
      loader({isLoading: false})
      return res.data
    }, (error) => {
      loader({isLoading: false})
      setAlert({
        title: error.name,
        type: 'alert',
        message: error.message,
        isShow: true
      })
    })
  }

  return {
    getGuideList,
    guideSave
  }
}
