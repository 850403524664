import React, { useEffect, useState } from 'react'
import { AttachmentWrap } from './styles'
import { AttachmentProps } from './types'

const Attachment = ({handleChange}: AttachmentProps) => {

  // 포커스
  const [focusActive, setFocusActive] = useState(false)
  const handleFocus = () => {
    setFocusActive(true)
  }
  const handleBlur = () => {
    setFocusActive(false)
  }

  // 파일 이름
  const [fileData, setFileData] = useState<any>(null)
  const handleChangeFile = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      console.log(reader)
      reader.onloadend = () => {
        setFileData(file)
        handleChange(file)
      }
      reader.readAsDataURL(file)
    }
  }

  // 삭제 버튼
  const [deleteBtn, setDeleteBtn] = useState(false)

  return (
    <AttachmentWrap
      className={focusActive ? 'focus' : ''}
      htmlFor='fileAttachment'
      onMouseEnter={() => setDeleteBtn(true)}
      onMouseLeave={() => setDeleteBtn(false)}
    >
      <span className={focusActive ? 'title focus' : 'title'}>첨부이미지</span>
      <span className='file-name'>{fileData?.name}</span>
      <input 
        type="file" 
        id='fileAttachment'
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChangeFile}
        accept='image/gif, image/jpeg, image/png'
      />
      {
        fileData && (
          <button 
            type="button"
            className={deleteBtn ? 'hover material-icons' : 'material-icons'}
            onClick={() => setFileData(null)}
          >close</button>
        )
      }
    </AttachmentWrap>
  )
}

export default Attachment