import {atom} from 'recoil'

interface PopupList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    title: string
    content: string
    order: number
    createdAt: string
    updatedAt: string
    userId: number
  }[]
}

interface Popup {
  id: number
  name: string
  url: string
  x: number
  y: number
  width: number
  height: number
  content: string
  order: number
  display: boolean
  set_period: boolean,
  from: string | null,
  to: string | null
}

export const popupListAtom = atom<PopupList>({
  key: 'popupListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const popupAtom = atom<Popup>({
  key: 'popupAtom',
  default: {
    id: 0,
    name: '',
    url: '',
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    content: '',
    order: 0,
    display: false,
    set_period: false,
    from: null,
    to: null
  }
})
