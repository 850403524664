import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {
  PopupBody,
  PopupWrap, WGPInfo,
  WGPInput,
  WGPInputWrap,
  WGPProductCnt,
  WGPProductPrice,
  WGPProductTitle, WGPSelect, WGPSubmit,
  WGPTitle, WGPWarning
} from "./styles";
import WingService from "./fetch";
import {OrderPriceAction} from "../../store/action/orderPrice";


const WingPayPopUp = ({onClose, userInfo, cardInfo, productInfo, order, orderInfo, movePage}) => {

  const {getOrderPirceTitle} = OrderPriceAction()

  const [loader, setLoader] = useState(false)
  const [info, setInfo] = useState({
    productTitle: '',
    productPrice: ''
  })

  const [product, setProduct] = useState([])
  const [card, setCard] = useState({
    number: "", // 카드번호
    expiry: "", // 카드 유효기간 YYMM
    cvv: "", // 카드 CVV 번호
    installment: 0 // 할부기간
  })
  const [cardMetaData, setCardMetaData] = useState({
    cardAuth: "true", // true
    authPw: "", // 카드비밀번호 앞 2자리
    authDob: "" // 생년월일
  })
  const [pay, setPay] = useState({
    trxType: "ONTR", // ONTR 기본
    payerName: "YUNAN1", // 구매자 이름
    payerEmail: "buyer1@a.com", // 구매자 이메일
    payerTel: "010-9999-9999", // 구매자 핸드폰
    trxId: "", // 윈 글로벌 페이 거래번호 ( 빈 값 )
    trackId: `gift_${new Date().getTime()}`, // 가맹점 주문번호
    amount: 5000, // 결제 금액
    udf1: "udf1", // 그대로 리턴되는 값 1
    udf2: "udf2", // 그대로 리턴되는 값 2
  })

  const callPay = () => {
    setLoader(true)
    const payload = {
      pay: {
        ...pay,
        card: {...card},
        metadata: {...cardMetaData},
        products: [...product]
      }
    }
    WingService.pay(payload)
      .then(res => {

        if (res.result.resultCd === '0000') {
          const result = {
            service_id: res.pay.tmnId,
            order_id: res.pay.trxId,
            track_id: res.pay.trackId,
            amount: res.pay.amount,
            trxdate: res.pay.trxDate,
            authcd: res.pay.authCd,
            response_code: res.result.resultCd,
          }
          order({
            ...orderInfo,
            ...result
          })
          setTimeout(() => {
            movePage && movePage()
            onClose()
          }, 1000)
        } else {
          if (res.result.advanceMsg !== '인증결제 필수값 없음 : authDob') {
            window.alert(`[${res.result.resultMsg}]\n${res.result.advanceMsg}`)
          } else {
            window.alert(`[승인실패]\n카드 정보가 다릅니다.\n카드정보를 확인해주세요.`)
          }
        }

        setLoader(false)
      })
  }

  useEffect(() => {
    getOrderPirceTitle(productInfo.reduce((prev, item) => prev + parseInt(item.price), 0))
      .then(res => {
        const productTitle = res.payload || '앱개발및 사이트제작'
        WingService.echo().then(res => {
          setPay({
            ...pay,
            payerName: userInfo.name,
            payerEmail: userInfo.email,
            payerTel: userInfo.phone,
            amount: productInfo.reduce((prev, item) => prev + parseInt(item.price), 0)
          })
          setProduct([{
            desc: '',
            name: productTitle,
            price: productInfo.reduce((prev, item) => prev + parseInt(item.price), 0),
            prodId: productInfo.prodId,
            qty: productInfo.reduce((prev, item) => prev + parseInt(item.qty), 0),
          }])
          setInfo({
            productTitle: productTitle,
            productPrice: productInfo.reduce((prev, item) => prev + parseInt(item.price), 0)
          })
          setCardMetaData({
            ...cardMetaData,
            authDob: userInfo.birth
            // authDob: '0101'
          })

          if (cardInfo) {
            setCard({
              number: cardInfo.number,
              expiry: cardInfo.expiry,
              cvv: cardInfo.cvv,
              authPw: cardInfo.authPw,
              authDob: cardInfo.authDob
            })
          }
        })
      })
  }, [])

  return <PopupWrap onClick={onClose}>
    <PopupBody onClick={(e) => e.stopPropagation()}>
      <WGPProductTitle>{info.productTitle}</WGPProductTitle>
      <WGPProductPrice>{info.productPrice.toLocaleString('ko-KR')} 원</WGPProductPrice>
      <WGPInfo>{pay.payerName} | {pay.payerEmail} | {pay.payerTel}</WGPInfo>

      <br/>

      <WGPTitle> 카드정보 </WGPTitle>
      <WGPInputWrap>
        <WGPInput type={'text'} placeholder={'카드번호'} onChange={(e) => setCard({...card, number: e.target.value})}
                  value={card.number}/>
        <WGPInput type={'text'} placeholder={'유효기간 (YYMM)'} onChange={(e) => setCard({...card, expiry: e.target.value})}
                  value={card.expiry}/>
        <WGPInput type={'text'} placeholder={'CVV 번호'} onChange={(e) => setCard({...card, cvv: e.target.value})}
                  value={card.cvv}/>
        <WGPInput type={'text'} placeholder={'비밀번호 앞 두 자리'}
                  onChange={(e) => setCardMetaData({...cardMetaData, authPw: e.target.value})}
                  value={cardMetaData.authPw}
                  maxLength={2}/>
        <WGPSelect onChange={e => {
          setCard({...card, installment: e.target.value})
        }} disabled={info.productPrice < 50000}>
          <option value={0}>일시불</option>
          <option value={2}>2개월</option>
          <option value={3}>3개월</option>
          <option value={4}>4개월</option>
          <option value={5}>5개월</option>
          <option value={6}>6개월</option>
        </WGPSelect>
        {/*<WGPInput type={'text'} placeholder={'생년월일'}*/}
        {/*          onChange={(e) => setCardMetaData({...cardMetaData, authDob: e.target.value})}*/}
        {/*          value={cardMetaData.authDob}/>*/}
      </WGPInputWrap>

      <WGPWarning>
        <br/>
        본 상품은 구매 즉시 핀번호가 발송되고 노출되므로<br/> 취소나 환불이 불가합니다.
        <br/>
      </WGPWarning>

      <WGPSubmit onClick={callPay} disabled={loader}>
        {loader ? '결제 진행중' : '결제하기'}
      </WGPSubmit>
    </PopupBody>
  </PopupWrap>
}

export default WingPayPopUp;
