import React, { useEffect, useState } from 'react'
import { InquiryWriteWrap, TitleWrap } from './styles'
import BorderInput from '../../components/inputBox/BorderInput'
import CheckLabel from '../../components/checkLabel'
import TextareaBox from '../../components/textareaBox'
import Attachment from '../../components/attachment'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authAtom } from '../../store/recoil/auth'
import { BoardAction } from '../../store/action/board'
import { useLocation, useNavigate } from 'react-router'
import { alertAtom } from '../../store/recoil/alert'

const InquiryWrite = () => {

  const navigate = useNavigate()

  const location = useLocation()

  const setAlert = useSetRecoilState(alertAtom)

  const auth = useRecoilValue(authAtom)
  const [inquiry, setInquiry] = useState({
    author: '',
    email: '',
    isSecrete: false,
    password: '',
    content: '',
    title: '',
    ask_by: 0,
    files: null
  })

  const { boardInquiry } = BoardAction()

  const handleSecretChange = () => {
    setInquiry({
      ...inquiry,
      isSecrete: !inquiry.isSecrete
    })
  }

  const handleChange = (k: string, v: any) => {
    setInquiry({
      ...inquiry,
      [k]: v
    })
  }

  const handleSubmit = () => {
    if (!inquiry.title) {
      setAlert({
        title: '문의하기',
        type: 'alert',
        message: '제목을 입력해주세요.',
        isShow: true
      })
    } else if (!inquiry.author) {
      setAlert({
        title: '문의하기',
        type: 'alert',
        message: '작성자를 입력해주세요.',
        isShow: true
      })
    } else if (!inquiry.email) {
      setAlert({
        title: '문의하기',
        type: 'alert',
        message: '이메일을 입력해주세요.',
        isShow: true
      })
    } else if (!inquiry.content) {
      setAlert({
        title: '문의하기',
        type: 'alert',
        message: '문의 내용을 입력해주세요.',
        isShow: true
      })
    } else {
      boardInquiry(inquiry)
      .then((res) => {
        if (res.status === 200) {
          setAlert({
            title: '문의하기',
            type: 'alert',
            message: '정상적으로 등록되었습니다.',
            isShow: true,
            action: navigate(-1)
          })
        } else {
          setAlert({
            title: '문의하기',
            type: 'alert',
            message: '문제가 발생했습니다.',
            isShow: true
          })
        }
      })
    }
  }

  useEffect(() => {
    !inquiry.isSecrete && setInquiry({
      ...inquiry,
      password: ''
    })
  }, [inquiry.isSecrete])

  useEffect(() => {
    setInquiry({
      ...inquiry,
      author: auth.name,
      email: auth.email,
      ask_by: auth.id || 0
    })
  }, [location, auth])

  return (
    <InquiryWriteWrap>
      <div className="container">
        <TitleWrap>
          <h2>문의하기</h2>
        </TitleWrap>
        <div className="write-wrap">
          <div className="info-wrap">
            <BorderInput 
              title='제목'
              value={inquiry.title}
              handleChange={(v) => handleChange('title', v)}
            />
          </div>
          <div className="info-wrap mo-db">
            <BorderInput 
              title='작성자'
              value={inquiry.author}
              handleChange={(v) => handleChange('author', v)}
            />
            <BorderInput 
              title='이메일'
              value={inquiry.email}
              handleChange={(v) => handleChange('email', v)}
            />
          </div>
          <div className="info-wrap">
            <div className="w50">
              <BorderInput 
                title='비밀번호'
                password={true}
                disabled={!inquiry.isSecrete}
                value={inquiry.password}
                handleChange={(v) => handleChange('password', v)}
              />
            </div>
            <div className="w50 center">
              <CheckLabel 
                text='비밀글'
                labelId='secret'
                checked={inquiry.isSecrete}
                handleChange={handleSecretChange}
              />
            </div>
          </div>
          <div className="info-wrap">
            <TextareaBox 
              title='문의 내용'
              value={inquiry.content}
              handleChange={(v) => handleChange('content', v)}
            />
          </div>
          <div className="info-wrap">
            <Attachment 
              handleChange={(e: any) => handleChange('files', e)}
            />
          </div>
        </div>
        <div className="btn-wrap">
          <button 
            type='button'
            onClick={() => navigate(-1)}
          >
            취소
          </button>
          <button 
            type='button'
            onClick={handleSubmit}
          >
            등록
          </button>
        </div>
      </div>
    </InquiryWriteWrap>
  )
}

export default InquiryWrite