import styled from "styled-components";

export const LoginWrap = styled.div`
  background-color: #f1f2f7;
  display: flex;
  min-height: calc(100vh - 44.75rem);
  align-items: center;
  .login-box {
    max-width: 48rem;
    width: 100%;
    height: auto;
    box-shadow: 0 0.3rem 0.1rem -0.2rem rgba(0,0,0,0.2), 0 0.2rem 0.2rem 0 rgba(0,0,0,0.14), 0 0.1rem 0.5rem 0 rgba(0,0,0,0.12);
    border-radius: 0.8rem;
    margin: 0 auto;
    background-color: #fff;
    .title-wrap {
      padding: 2.4rem 0;
      border-bottom: 0.1rem solid #dfdfdf;
      h2 {
        font-size: 3.6rem;
        font-weight: 500;
        text-align: center;
      }
    }
    .info-wrap {
      padding: 3.2rem;
    }
    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2.4rem;
      border-top: 0.1rem solid #dfdfdf;
      > a {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  @media screen and (max-width: 1240px) {
    min-height: calc(100vh - 36.65rem);
  }
  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 45.65rem);
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 41.45rem);
    padding: 8rem 1.5rem;
  }
  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 33.85rem);
    padding: 6rem 1.5rem;
    .login-box {
      .title-wrap {
        padding: 1.5rem 0;
        h2 {
          font-size: 2.8rem;
        }
      }
      .info-wrap {
        padding: 1.5rem;
      }
      .btn-wrap {
        padding: 1.5rem;
        a {
          font-size: 1.4rem;
        }
      }
    }
  }
`
