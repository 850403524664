import {atom} from 'recoil'

interface PolicyList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    title: string;
    content: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    userId: number;
  }[]
}

interface Policy {
  id: number;
  code: string;
  title: string;
  content: string;
  created_at: string;
  updated_at: string;
  required: boolean
}

export const policyListAtom = atom<PolicyList>({
  key: 'policyListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const policyAtom = atom<Policy>({
  key: 'policyAtom',
  default: {
    id: 0,
    code: '',
    title: '',
    required: false,
    content: '',
    created_at: '',
    updated_at: ''
  }
})
