import React, { useEffect, useState } from 'react'
import { BorderInputWrap } from './styles'
import { InputBoxProps } from './types'
import Input from '../../module/input'

const BorderInput = ({title, marginTop, password, disabled, closeNone, value, handleChange, readOnly}: InputBoxProps) => {

  const [focusActive, setFocusActive] = useState(false)
  const [passwordType, setPasswordType] = useState('password')
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [textValue, setTextValue] = useState('')
  
  const handleFocus = () => {
    if (!disabled) {
      setFocusActive(true)
    }
  }
  const handleBlur = () => {
    setFocusActive(false)
  }

  const handlePasswordType = () => {
    console.log(1)
    if (passwordType === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }

  const handleChangeValue = (v: string) => {
    setTextValue(v)
    handleChange && handleChange(v)
  }

  const handleDeleteValue = () => {
    setTextValue('')
    handleChange && handleChange('')
  }

  useEffect(() => {
    textValue.length > 0 ? setDeleteStatus(true) : setDeleteStatus(false)
  }, [textValue])

  useEffect(() => {
    setTextValue(value || '')
  }, [value])

  return (
    <BorderInputWrap 
      className={focusActive ? 'focus' : ''}
      style={marginTop ? {marginTop: marginTop} : {}}
    >
      <span className={disabled ? 'disabled' : focusActive ? 'focus' : ''}>{title}</span>
      <Input 
        type={password ? passwordType : 'text'}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        borderNone={true}
        value={textValue}
        handleChange={handleChangeValue}
        disabled={disabled}
        readOnly={readOnly}
      />
      {
        !closeNone && (
          <button 
            type="button"
            className={disabled ? 'disabled material-icons' : focusActive ? "focus material-icons" : "material-icons"}
            style={deleteStatus ? {opacity: 1} : {opacity: 0}}
            onClick={() => !disabled && handleDeleteValue()}
          >close</button>
        )
      }
      {
        password && (
          <button 
            type='button'
            className={disabled ? 'disabled material-icons' : focusActive ? "focus material-icons" : "material-icons"}
            onClick={() => !disabled && handlePasswordType()}
          >
            <i className="material-icons" translate="no">{
              passwordType === 'password' ? 'visibility_off' : 'remove_red_eye'
            }</i>
          </button>
        )
      }
    </BorderInputWrap>
  )
}

export default BorderInput
