import React from 'react'
import { BoardDetailWrap } from './styles'
import { BoardDetailProps } from './types'
import { useNavigate } from 'react-router';

const BoardDetail = ({title, date, name, count, contents, prev, next, file}: BoardDetailProps) => {

  // Navigate
  const navigate = useNavigate()

  // 뒤로가기
  const handleGoBack = () => {
    navigate(-1)
  };

  // 파일
  const fileData = file && JSON.parse(file)[0]
  const filePath = file && JSON.parse(file)[0].path
  const fileName = fileData?.path?.split('/').pop()


  const handleDownload = () => {
    // const url = process.env.REACT_APP_IMAGE_URL + src
    const url = process.env.REACT_APP_BASE_URL + filePath
    fetch(url, {
      method: "GET",
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <BoardDetailWrap>
      <div className='hd'>
        <div className='title-wrap'>
          <h4>제목 : {title}</h4>
          <div className="date">작성일자 : {date}</div>
        </div>
        {
          name && count && (
            <div className="info-wrap">
              <span>작성자 : {name}</span>
              <span>조회수 : {count}</span>
            </div>
          )
        }
      </div>
      {
        file && (
          <div className="file-wrap">
            <button onClick={() => handleDownload()}>
              <img src="/image/download-icon.png" alt="다운로드 아이콘" />
              <span>첨부파일 다운로드</span>
            </button>
          </div>
        )
      }
      <div className='contents' dangerouslySetInnerHTML={{__html: contents}}></div>
      <div className='ft'>
        {
          prev && (
            <button 
              type='button'
              onClick={() => navigate(`/notice/detail/${prev}`)}
            >
              <span>이전글</span>
            </button>
          )
        }
        {
          next && (
            <button 
              type='button'
              onClick={() => navigate(`/notice/detail/${next}`)}
            >
              <span>다음글</span>
            </button>
          )
        }
        {
          !(prev || next) && (
            <button 
              type='button'
              onClick={handleGoBack}
            >
              <img src="/image/arrow-left-icon.png" alt="이전 화살표" />
              <span>뒤로가기</span>
            </button>
          )
        }
      </div>
    </BoardDetailWrap>
  )
}

export default BoardDetail