import React from 'react'
import { OrderWrap, TitleWrap } from './styles'
import OrderList from '../../components/list/OrderList'
import OrderBox from '../../components/orderBox'

const Order = () => {
  return (
    <OrderWrap>
      <div className="container">
        <TitleWrap>
          <h2>주문하기</h2>
        </TitleWrap>
        <OrderList />
        <OrderBox />
      </div>
    </OrderWrap>
  )
}

export default Order