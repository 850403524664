import React from 'react'

import {Base} from './Base'
import {Layout} from './Layout'
import {Common} from './Common'
import {Variable} from './Common/Variable'

export const Style = () => {
  return (
    <>
      <Base/>
      <Layout/>
      <Common/>
      <Variable/>
    </>
  )
}

export default Style
