import {atom} from 'recoil'

interface ProductList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    product_id: number;
    brand: string;
    image: string;
    category: string;
    originalPrice: number;
    sale_price: number | null;
    jaegoCnt: number;
    salePercent: string;
    seller: number;
    hot: number;
    giftSale: number;
  }[]
}

interface Product {
  sell_type: 0 | 1;
  brand_id: number;
  title: string;
  price: number;
  hot: 0 | 1;
  seller: 0 | 1;
  giftsale: 0 | 1;
  hot_order: number;
  pick_order: number;
  giftsale_order: number;
  pay_type: string;
  is_main: 0 | 1;
  is_use: 0 | 1;
  file: any;
  is_gift: 0 | 1;
  resell_promotion: '',
  isbarcode: 0 | 1;
  image: any;
  memo: string;
}

export const productListAtom = atom<ProductList>({
  key: 'productListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const productAtom = atom<Product>({
  key: 'productAtom',
  default: {
    sell_type: 0 ,
    brand_id: 0 ,
    title: '',
    price: 0 ,
    hot: 0 ,
    seller: 0 ,
    giftsale: 0 ,
    hot_order: 0 ,
    pick_order: 0 ,
    giftsale_order: 0 ,
    pay_type: '',
    is_main: 0 ,
    is_use: 0 ,
    is_gift: 0,
    resell_promotion: '',
    isbarcode: 0,
    file: null,
    image: '',
    memo: '',
  }
})
