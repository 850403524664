import React, { useEffect } from 'react'
import { NoticeWrap, TitleWrap } from './styles'
import BoardDetail from '../../components/board/Detail'
import { NoticeAction } from '../../store/action'
import { useLocation } from 'react-router'
import { useRecoilValue } from 'recoil'
import { noticeAtom } from '../../store/recoil/notice'
import moment from 'moment'

const NoticeDetail = () => {

  // Location
  const location = useLocation()

  // Notice Action
  const {getNotice} = NoticeAction()

  // 공지사항 상세 가져오기
  useEffect(() => {
    getNotice({
      id: Number(location.pathname.split('/')[3])
    })
  }, [location])

  // 공지사항 데이터
  const notice = useRecoilValue(noticeAtom)

  console.log(notice)

  return (
    <NoticeWrap>
      <div className="container">
        <TitleWrap>
          <h2>공지사항</h2>
        </TitleWrap>
        <BoardDetail 
          title={notice?.title}
          date={moment(notice?.created_at).format('YYYY-MM-DD')}
          contents={notice?.content}
          prev={notice?.prev}
          next={notice?.next}
        />
      </div>
    </NoticeWrap>
  )
}

export default NoticeDetail