import React, {useCallback, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {useRecoilValue} from 'recoil'
import {loaderAtom} from '../../../store/recoil/loader'
import {AuthAction} from '../../../store/action'

import {styled} from 'styled-components'
import {Base} from '../../layout/default/style/Base'
import {Common} from '../../layout/default/style/Common'

import InputField from '../../module/input'
import LoadingButton from '../../module/loadingButton'
import Alert from '../../module/alert'
import Social from '../../module/social'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: scroll;
`

const Join = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 32rem;
  width: 40rem;
  margin: auto;
`

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  font-size: 1.3rem;
  font-weight: 300;
  color: #666666;
  margin: 2rem 0;

  .btn-join {
    display: flex;
    flex: 1;
    flex-grow: 0;
    place-self: flex-start;
    font-size: 1.4rem;
    font-weight: 400;
    background: #efebef;
    border: .1rem solid #efebef;
    color: #333333;
    padding: .4rem 1rem;
    border-radius: .5rem;
    margin-top: 1rem;
  }

  h4, h5 {
    font-size: 1.7rem;
    text-align: left;
  }

  .description {
    font-size: 1.5rem;
  }
`

const Logo = styled(Link)`
  background-image: url('../../../images/logo.png');
  background-position: left;
  background-size: 5rem;
  background-repeat: no-repeat;
  text-indent: -999999999rem;
  height: 5rem;
  width: 5rem;
  margin-bottom: 4rem;
`

const Form = styled.form`
  display: flex;
  flex: 1;
  align-self: stretch;

  fieldset {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
  }

  /*******************
  input component 스타일 재설정
  ********************/

  input[type=text],
  input[type=password] {
    border-radius: 3rem;
    padding-left: 2.2rem;

    ~ label {
      font-size: 1.4rem;
      left: 1.2rem;
    }
  }
`

const Forgot = styled.div`
  margin-top: 5rem;
  text-align: center;

  a {
    color: #666666;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
  }

  span.sep {
    display: inline-block;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
  }
`

const Index = () => {

  const loader = useRecoilValue(loaderAtom)
  const {authLogin} = AuthAction()

  const [state, setState] = useState<any>({
    email: '',
    password: '',
    name: '',
    phone: ''
  })

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = async () => {

  }

  return (
    <>
      <Base/>
      <Common/>
      <Container>
        <Join>
          <Logo to="/">ZooToox</Logo>

          <Info>
            <h5 className="m-t-20">이미 아이디가 있으신가요?</h5>
            <span className="description">로그인 후 주툭스의 서비스를 이용하세요.</span>
            <Link to="/auth" className="btn btn-join">
              로그인하러 가기
            </Link>
          </Info>

          <Form name="form" onSubmit={handleSubmit}>
            <fieldset>
              <InputField
                useAnimation={true}
                type={'text'}
                id="email"
                name="email"
                placeholder="가입하실 이메일을 입력하세요"
                onChange={(e) => handleChange(e)}
                check={true}
              />

              <InputField
                useAnimation={true}
                type={'password'}
                id="password"
                name="password"
                placeholder="비밀번호"
                minLength={8}
                maxLength={16}
                onChange={(e) => handleChange(e)}
                check={true}
              />

              <InputField
                useAnimation={true}
                type={'password'}
                id="repeatPassword"
                name="repeatPassword"
                placeholder="비밀번호를 다시한번 입력해주세요"
                onChange={(e) => handleChange(e)}
                check={true}
                isChecked={state.repeatPassword === state.password ? true : false}
              />

              <InputField
                useAnimation={true}
                type={'text'}
                id="name"
                name="name"
                placeholder="이름"
                maxLength={20}
                onChange={(e) => handleChange(e)}
              />

              {/*<InputField*/}
              {/*  useAnimation={true}*/}
              {/*  type={'text'}*/}
              {/*  id="phone"*/}
              {/*  name="phone"*/}
              {/*  placeholder="전화번호"*/}
              {/*  onChange={(e) => handleChange(e)}*/}
              {/*  numberType={'number'}*/}
              {/*/>*/}

              <LoadingButton
                loading={loader.isLoading}
                onClick={() => handleSubmit}
              >
                회원가입
              </LoadingButton>
            </fieldset>
          </Form>

          <Forgot>
            <Link to="/auth/find/email">아이디 찾기</Link>
            <span className="sep after"></span>
            <Link to="/auth/find/pass">비밀번호 찾기</Link>
          </Forgot>

          <Social/>
        </Join>
        <Alert/>
      </Container>
    </>
  )
}

export default Index
