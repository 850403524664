import React from 'react'
import { CheckLabelWrap } from './styles'
import CheckBox from '../../module/checkbox'
import { CheckLabelProps } from './types'

const CheckLabel = ({text, labelId, checked, handleChange}: CheckLabelProps) => {
  return (
    <CheckLabelWrap>
      <CheckBox 
        id={labelId}
        checked={checked}
        handleChange={handleChange}
      />
      <label htmlFor={labelId}>
        {text}
      </label>
    </CheckLabelWrap>
  )
}

export default CheckLabel