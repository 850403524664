import React from 'react'
import { Step03Wrap } from './styles'
import { JoinStepProps } from './types'
import { Link } from 'react-router-dom'

const Step03 = ({active, handleStep}: JoinStepProps) => {
  return (
    <Step03Wrap className={active ? 'active' : ''}>
      <div className='box'>
        <div className="circle">
          <i></i>
        </div>
        <h5>회원가입이 완료되었습니다.</h5>
        <p>가입해 주셔서 감사합니다.</p>
        <Link to={'/login'}>로그인 페이지로 이동</Link>
      </div>
    </Step03Wrap>
  )
}

export default Step03