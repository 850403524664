import React from 'react'
import { MobileHeaderWrap } from './styles'
import { Link } from 'react-router-dom'

interface MobileHeaderProps {
  handleMenuClick: () => void
}

const MobileHeader = ({handleMenuClick}: MobileHeaderProps) => {
  return (
    <MobileHeaderWrap>
      <div className="container">
        <button 
          type='button' 
          className="menu-btn"
          onClick={handleMenuClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <h1 id='logo'>
          <Link to={'/'} className='ir'>SWITCHCON</Link>
        </h1>
      </div>
    </MobileHeaderWrap>
  )
}

export default MobileHeader