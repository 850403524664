import {atom} from 'recoil'

interface Category {
  id: number
  name: string
}

interface CategoryList {
  data: {
    id: number
    name: string
  }[]
  pageInfo: {
    page: number
    size: number
    total: number
  }
}

export const categoryListAtom = atom<CategoryList>({
  key: 'categoryListAtom',
  default: {
    data: [],
    pageInfo: {
      total: 0,
      page: 0,
      size: 0
    }
  }
})

export const categoryAtom = atom<Category>({
  key: 'categoryAtom',
  default: {
    id: 0,
    name: ''
  }
})
