import React, { useEffect, useState } from 'react'
import { PolicyWrap } from './styles'
import { PolicyAction } from '../../store/action'

const Policy = () => {

  // Policy Action
  const { getPolicyList } = PolicyAction()

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10
  })

  const [policy, setPolicy] = useState('')

  useEffect(() => {
    getPolicyList(pagination)
    .then((res) => {
      const data = res?.payload.data?.filter((item: any) => item.code === 'privacy' && item.required === 1)[0]
      setPolicy(data?.content)
    })
  }, [])

  return (
    <PolicyWrap>
      <div className="container">
        <div className="box-wrap">
          <div className="title-wrap">
            <h2>개인정보 취급방침</h2>
          </div>
          <div className="contents-wrap">
            <div className="contents" dangerouslySetInnerHTML={{ __html: policy }} />
          </div>
        </div>
      </div>
    </PolicyWrap>
  )
}

export default Policy