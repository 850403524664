import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {bannerListAtom, bannerAtom} from '../recoil/banner'

export const BannerAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [bannerList, setBannerList] = useRecoilState(bannerListAtom)
  const setBanner = useSetRecoilState(bannerAtom)

  const regisBanner = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('type', params.type)
    formData.append('code', params.code)
    formData.append('title', params.title)

    params.banner.forEach((item: any, index: number) => {
      formData.append('files', item.file)
    })

    params.banner.forEach((item: any, index: number) => {
      formData.append('links', item.link)
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/banner/add',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBannerList({
          ...bannerList,
          data: [{
            ...params,
            id: response.data.payload.id
          }, ...bannerList.data],
          pageInfo: {
            ...bannerList.pageInfo,
            total: bannerList.pageInfo.total + 1
          }
        })

        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 등록되었습니다.',
          isShow: true
        })
        navigate('/admin/banner')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateBanner = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('type', params.type)
    formData.append('code', params.code)
    formData.append('title', params.title)

    params.banner.forEach((item: any, index: number) => {
      formData.append('files', item.file)
    })

    params.banner.forEach((item: any, index: number) => {
      console.log(item.link)
      formData.append('links', item.link ? item.link : '')
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/banner/update',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate(-1)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getBannerList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/banner/list`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBannerList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getBanner = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/banner/detail`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBanner(response.data.payload.data)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteBanner = (params: any, cb?: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/banner/deleteImage',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        cb && cb()
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regisBanner,
    updateBanner,
    getBannerList,
    getBanner,
    deleteBanner
  }
}
