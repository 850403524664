import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useResetRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {authAtom} from '../recoil/auth'

interface UpdateUserProps {
  email0: string
  email1: string
  phone0: string
  phone1: string
  phone2: string
  password: string
  post: string
  address0: string
  address1: string
  id: number
}

interface FindUserInfoProps {
  name: string
  phone: string
  userId?: string
}

export const AuthAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setAuth = useSetRecoilState(authAtom)
  const resetAuth = useResetRecoilState(authAtom)

  const authJoin = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/auth/join',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.response.data.title,
          type: 'alert',
          message: error.response.data.message,
          isShow: true
        })
        return
      }
    )
  }

  const authLogin = (userId: string, password: string) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/auth/login',
      params: {userId: userId, password: password}
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAuth(response.data.payload)
        navigate('/')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.response.data.title,
          type: 'alert',
          message: error.response.data.message,
          isShow: true
        })
      }
    )
  }

  const authAdminLogin = (userId: string, password: string) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/auth/login',
      params: {userId: userId, password: password}
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAuth(response.data.payload)
        navigate('/admin')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.response.data.title,
          type: 'alert',
          message: error.response.data.message,
          isShow: true
        })
      }
    )
  }

  const authLogout = (token: string) => {

    defaultService.handleService({
      method: 'post',
      endPoint: '/auth/logout',
      params: {token: token}
    }).then(
      response => {
        loader({isLoading: false})
        resetAuth()
        navigate('/')
        setAlert({
          title: response.data.title,
          type: 'alert',
          message: response.data.message,
          isShow: true
        })
      },
      error => {
        loader({isLoading: false})
        resetAuth()
        navigate('/')
        setAlert({
          title: error.title,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const authAdminLogout = (params: any) => {

    defaultService.handleService({
      method: 'post',
      endPoint: '/user/logout',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/admin/auth')
      },
      error => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/admin/auth')

        // setAlert({
        //   title: error.response.data.title,
        //   type: 'alert',
        //   message: error.response.data.message,
        //   isShow: true
        // })
      }
    )
  }

  const authUpdate = async (params: UpdateUserProps) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/auth/updateUser',
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        return response.data
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.title,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const authDelete = async (id: number) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/auth/deleteUser',
      params: {id: id}
    }).then(
      response => {
        loader({isLoading: false})
        resetAuth()
        navigate('/')
        setAlert({
          title: response.data.title,
          type: 'alert',
          message: response.data.message,
          isShow: true
        })
        return response.data
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.title,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const findUserId = async (params: FindUserInfoProps) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/auth/find/userId',
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        return response.data
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.title,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const findUserPassword = async (params: FindUserInfoProps) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/auth/find/password',
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        return response.data
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.title,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    authJoin,
    authLogin,
    authLogout,
    authAdminLogin,
    authAdminLogout,
    authUpdate,
    authDelete,
    findUserId,
    findUserPassword
  }
}
