import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {policyListAtom, policyAtom} from '../recoil/policy'
import {nameAtom, nameListAtom} from '../recoil/name'

export const NameAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [nameList, setNameList] = useRecoilState(nameListAtom)
  const setName = useSetRecoilState(nameAtom)

  const regisName = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/orderPrice/insert/',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 등록되었습니다.',
          isShow: true
        })
        !params.id && navigate(-1)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateName = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/orderPrice/update',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate(-1)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getNameList = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/orderPrice/list`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setNameList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getName = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/orderPrice/detail/`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setName(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteName = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/orderPrice/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        const filteredList = nameList.data.filter(item => !params.ids.includes(item.id))
        setNameList({
          ...nameList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regisName,
    updateName,
    getNameList,
    getName,
    deleteName
  }
}
