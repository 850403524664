import styled from "styled-components";

export const BannerSlider = styled.div`
  .banner {
    height: 34.479vw;
  }
  @media screen and (max-width: 720px) {
    .banner {
      height: 125vw;
    }
  }
`