import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {popupListAtom, popupAtom} from '../recoil/popup'

export const PopUpAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [popUpList, setPopUpList] = useRecoilState(popupListAtom)
  const setPopUp = useSetRecoilState(popupAtom)

  const regisPopUp = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/popup/insert',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 등록되었습니다.',
          isShow: true
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updatePopUp = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/popup/update',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPopUpList = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/popup/list`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPopUpList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPopUp = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/popup/detail/`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPopUp(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deletePopUp = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/popup/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        const filteredList = popUpList.data.filter(item => !params.ids.includes(item.id))
        setPopUpList({
          ...popUpList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regisPopUp,
    updatePopUp,
    getPopUpList,
    getPopUp,
    deletePopUp
  }
}
