import styled from "styled-components";

export const CheckLabelWrap = styled.div`
  display: flex;
  align-items: center;
  label {
    font-size: 1.6rem;
    color: rgba(0,0,0,.6);
    cursor: pointer;
    margin-left: 0.8rem;
  }
  @media screen and (max-width: 500px) {
    label {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }
  }
`