import {atom} from 'recoil'

interface PushList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    subject: string
    content: string
    createdAt: string
    updatedAt: string
    userId: number
  }[]
}

export const pushListAtom = atom<PushList>({
  key: 'pushListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})
