import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { BannerSlider } from './styles';
import { UserBannerAction } from '../../store/action/userBanner';

const Banner = () => {

  // BannerList
  const [pcBannerList, setPcBannerList] = useState<any>([])
  const [mobileBannerList, setMobileBannerList] = useState<any>([])

  // Banner Action
  const { getBannerList, getBanner } = UserBannerAction()

  // Pagination
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10
  })

  // 배너 리스트 가져오기
  useEffect(() => {
    getBannerList(pagination)
    .then((res) => {
      const idArray = res?.data?.map((item: any) => item.id)
      if (idArray.length > 0) {
        for (let i = 0; i < idArray.length; i++) {
          getBanner({bannerId: idArray[i]})
          .then((res) => {
            if (res?.title?.includes('pc')) {
              setPcBannerList(JSON.parse(res.files))
            }
            if (res?.title?.includes('mobile')) {
              setMobileBannerList(JSON.parse(res.files))
            }
          })
        }
      }
    })
  }, [])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return pcBannerList.length > 0 ? (
    <BannerSlider>
      <Swiper
        loop={true}
        spaceBetween={0}
        slidesPerView={1}
      >
        {
          (windowWidth > 720 ? pcBannerList : mobileBannerList).map((item: any, index: number) => {
            const url = process.env.REACT_APP_BASE_URL + item?.path
            return (
              <SwiperSlide
                key={index}
              >
                <div className='banner' style={{background: `url('${url}') no-repeat center / cover`}}></div>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </BannerSlider>
  ) : null
}

export default Banner
