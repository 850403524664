import React, {useEffect, useRef, useState} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'

import Style from './default/style/'
import Header from './default/Header'
import Nav from './default/Nav'
import Footer from './default/Footer'
import Alert from '../module/alert/'
import Loader from '../module/loader/'

const DefaultLayout = () => {

  const location = useLocation()

  const [navStatus, setNavStatus] = useState(false)

  const handleClickNav = () => {
    setNavStatus(!navStatus)
  }

  useEffect(() => {
    setNavStatus(false)
  }, [location])

  return (
    <>
      <Style/>
      <Header 
        handleClickNav={handleClickNav} 
      />
      <main>
        <div className={navStatus ? 'nav active' : 'nav'}>
          <Nav />
        </div>
        <div className="content">
          <div className="inner">
            <Outlet/>
          </div>
        </div>
      </main>
      <Footer/>
      <Alert/>
      <Loader/>
    </>
  )
}

export default DefaultLayout
