import React, {useEffect} from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {authAtom} from '../../../store/recoil/auth'

import Login from './Login'
import Join from './Join'
import FindId from './FindEmail'
import FindPass from './FindPass'
// import NotFound from '../../page/NotFound'

const Index = () => {
  const navigate = useNavigate()
  const auth = useRecoilValue(authAtom)

  useEffect(() => {
    auth.role > 0 && navigate('/')
  }, [])

  const authRoutes = [
    {name: 'Login', path: '/*', element: Login},
    {name: 'Join', path: '/join', element: Join},
    {name: 'FindId', path: '/find/email', element: FindId},
    {name: 'FindPass', path: '/find/pass', element: FindPass}
    // { name: '404', path: '*', element: NotFound },
  ]

  return (
    <Routes>
      {authRoutes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          element={<route.element/>}
        />
      ))}
    </Routes>
  )
}
export default Index
