import React, { useState } from 'react'
import { SelectBox } from './styles'
import { SelectProps } from './types'

const Select = ({
                  optionList,
                  value,
                  handleChange,
                  firstOption,
                  borderType,
                  flex,
                  disabled
}: SelectProps) => {

  return (
    <SelectBox
      className={borderType ? 'border-type' : ''}
      style={flex ? {flex: flex} : {}}
    >
      <select
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
      >
        {firstOption && <option value="" disabled>{firstOption}</option>}
        {
          optionList.map((item, index) => {
            return (
              <option
                key={index}
                value={item.value}
              >{item.label}</option>
            )
          })
        }
      </select>
      <span className='material-icons'>arrow_drop_down</span>
    </SelectBox>
  )
}

export default Select
