import {createGlobalStyle} from 'styled-components'

export const Base = createGlobalStyle`
  body,
  html {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0 auto;
    background: #ffffff;
    font-weight: 100;
    zoom: 1;
    font-size: 10px;
    overflow: hidden;
  }

  * {
    font-family: 'Spoqa Han Sans Neo', 'Malgun Gothic', 'sans-serif', 'Apple SD Neo Gothic', 'Raleway';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    letter-spacing: -0.03rem;
    line-height: 1.5;
    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #333333;
    display: block;
  }

  a:hover {
    text-decoration: none;
  }

  a:focus,
  button:focus,
  input:focus,
  label:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  label,
  button,
  select {
    cursor: pointer;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  dd,
  dl,
  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  p {
    margin: 0;
  }

  button,
  input {
    background: transparent;
    padding: 0;
    border: 0;
  }

  input[type=email],
  input[type=password],
  input[type=text],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=text]::-ms-clear {
    display: none;
  }

  *:focus {
    outline: none;
  }

  ::placeholder {
    color: #bbbbbb;
    opacity: 1;
    font-size: 1.4rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #666666 !important;
  }
`
