import styled from "styled-components";

export const SelectBox = styled.label`
  display: block;
  position: relative;
  background-color: #fff;
  margin-top: 1.4rem;
  select {
    width: 100%;
    height: 3.2rem;
    border: 0;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.42);
    padding-left: 1.5rem;
    padding-right: 5.4rem;
    appearance: none;
    position: relative;
    z-index: 9;
    background: transparent;
    outline: none;
    font-size: 1.6rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
  span {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.4rem;
  }
  &.border-type {
    margin-top: 0;
    select {
      height: 5.6rem;
      border: 0.1rem solid #dfdfdf;
      border-radius: 0.4rem;
    }
  }
  @media screen and (max-width: 500px) {
    select{
      height: 3rem;
      font-size: 1.4rem;
    }
    &.border-type {
      select {
        height: 5rem;
      }
    }
  }
`