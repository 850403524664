import styled, {css} from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const Tree = styled.div`
  display: flex;
  position: relative;
  margin-bottom: .2rem;

  label {
    display: flex;
    flex: 1;
    margin: .2rem 0 .2rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    color: #444444;
    
    span.url {
      padding-left: .5rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: #999999;
    }
  }

  span.icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem 0 0 0;
    background-size: 1rem auto;
  }

  input[type=checkbox] {
    display: none;
  }

  input:checked ~ label span.icon {
    transform: rotate(180deg);
  }
`

const Action = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(98%, -50%) !important;
  z-index: 9;
`

const AddAction = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
`

const ModyfiAction = styled.div`
  display: flex;
  flex: 1;
  padding-left: 2.4rem;
`

const TreeItem = styled.div<{isLevel?: number}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  margin: .5rem 0;
`

export const Style = {
  Container,
  Tree,
  Action,
  AddAction,
  ModyfiAction,
  TreeItem
}
