import React from 'react';
import { PaginationProps } from './types';
import { PaginationWrap } from './styles';

const Pagination = ({ currentPage, totalPages, onPageChange, size }: PaginationProps) => {
  
  
  const totalPage = Math.ceil(totalPages / size);

  const generatePaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    let startPage = 0;
    let endPage = totalPage - 1;

    if (totalPage > maxButtonsToShow) {
      const halfMaxButtonsToShow = Math.floor(maxButtonsToShow / 2);
    
      if (currentPage > halfMaxButtonsToShow) {
        startPage = currentPage - halfMaxButtonsToShow;
        endPage = currentPage + halfMaxButtonsToShow;
    
        if (endPage >= totalPage) {
          endPage = totalPage - 1;
          startPage = totalPage - maxButtonsToShow;
        }
      } else {
        endPage = maxButtonsToShow - 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i}>
          <button
            onClick={() => onPageChange(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i + 1}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <PaginationWrap>
      <li>
        <button 
          type='button'
          className={currentPage === 0 ? 'disabled' : ''}
          onClick={() => currentPage > 0 && onPageChange(currentPage - 1)}
        >
          <img src="/image/arrow-left-icon.png" alt="이전 버튼" />
        </button>
      </li>   
      {generatePaginationButtons()}
      <li>
        <button
          type='button'
          className={currentPage === totalPages - 1 ? 'disabled' : ''} 
          onClick={() => currentPage < totalPage - 1 && onPageChange(currentPage + 1)}
        >
          <img src="/image/arrow-right-icon.png" alt="다음 버튼" />
        </button>
      </li>
    </PaginationWrap>
  );
};

export default Pagination;
