import styled from "styled-components";

export const MyOrderWrap = styled.div`
  flex: 1;
  @media screen and (max-width: 1240px) {
    width: 100%;
  }
`

export const TitleWrap = styled.div`
  padding: 5rem 0 6rem;
  h2 {
    font-size: 2.8rem;
    font-weight: 500;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 6rem;
    h2 {
      font-size: 2.5rem;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 0 3rem;
    h2 {
      font-size: 2.2rem;
    }
  }
`