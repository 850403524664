import {atom} from 'recoil'

interface BannerList {
  data: any[]
  pageInfo: {
    page: number
    size: number
    total: number
  }
}

interface Banner {
  id: number;
  title: string;
  files: any;
}

export const bannerListAtom = atom<BannerList>({
  key: 'bannerListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const bannerAtom = atom<Banner>({
  key: 'bannerAtom',
  default: {
    id: 0,
    title: '',
    files: '',
  }
})
