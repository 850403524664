import React from 'react'
import { MyOrderWrap, TitleWrap } from './styles'
import OrderHistory from '../../../components/list/OrderHistory'

const MyOrder = () => {
  return (
    <MyOrderWrap>
      <TitleWrap>
        <h2>상품권 주문내역</h2>
      </TitleWrap>
      <OrderHistory />
    </MyOrderWrap>
  )
}

export default MyOrder