export const optionList = [
  {
    value: '',
    label: ':: 직접입력 ::'
  },
  {
    value: 'naver.com',
    label: 'naver.com'
  },
  {
    value: 'gmail.com',
    label: 'gmail.com'
  },
  {
    value: 'daum.net',
    label: 'daum.net'
  },
  {
    value: 'hanmail.net',
    label: 'hanmail.net'
  },
]