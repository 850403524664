import React, { useEffect, useState } from 'react'
import { MyProfileWrap, TitleWrap } from './styles'
import InputBox from '../../../components/inputBox'
import Select from '../../../module/select'
import { optionList } from './dummy'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { authAtom } from '../../../store/recoil/auth'
import Address from '../../../components/address'
import { AuthAction } from '../../../store/action'
import { alertAtom } from '../../../store/recoil/alert'
import { useNavigate } from 'react-router'

const MyProfile = () => {

  // navigate
  const navigate = useNavigate()

  // Auth Action
  const { authUpdate, authDelete } = AuthAction()

  // Alert
  const setAlert = useSetRecoilState(alertAtom)

  // 유저 정보
  const [auth, setAuth] = useRecoilState(authAtom)

  // 변경할 유저 정보
  const [userInfo, setUserInfo] = useState({
    password: '',
    email0: '',
    email1: '',
    post: '',
    address0: '',
    address1: '',
    phone0: '',
    phone1: '',
    phone2: '',
    id: 0
  })

  // 이메일 선택박스
  const [selectValue, setSelectValue] = useState('')
  const handleChangeSelect =  (v: string) => {
    setUserInfo({
      ...userInfo,
      email1: v
    })
    setSelectValue(v)
  }

  // 이메일 선택 상태 체크
  const handleChangeEmail = (v: string) => {
    if (!selectValue) {
      setUserInfo({
        ...userInfo,
        email1: v
      })
    }
  }

  // 우편주소 모달
  const [addressModal, setAddressModal] = useState(false)
  const handleAddressModal = (v: boolean) => {
    setAddressModal(v)
  }
  const handleAddress = (code: string, address: string) => {
    setUserInfo({
      ...userInfo,
      post: code,
      address0: address
    })
  }

   // 초기 방 진입 시 데이터 삽입
   useEffect(() => {
    setSelectValue(auth?.email?.split('@')[1])
    setUserInfo({
      ...userInfo,
      email0: auth?.email?.split('@')[0],
      email1: auth?.email?.split('@')[1],
      post: auth?.post,
      address0: auth?.address0,
      address1: auth?.address1,
      phone0: auth?.phone?.slice(0, 3),
      phone1: auth?.phone?.slice(3, 7),
      phone2: auth?.phone?.slice(7, 11),
      id: auth?.id
    })
  }, [auth])


  // 비밀번호 조건 확인
  const checkPasswordValidity = (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/
    return regex.test(password)
  }

  // 회원정보 수정
  const handleSubmit = () => {
    if (userInfo.password.length > 0 && !checkPasswordValidity(userInfo.password)) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '비밀번호는 회원 아이디와 동일하지 않아야 하며 영어+숫자+특수문자 혼합 문자로 최소 8~20자리입니다.',
        isShow: true
      })
    } else if (!userInfo.phone0 || !userInfo.phone1 || !userInfo.phone2) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '휴대폰번호를 입력해주세요.',
        isShow: true
      })
    } else if (!userInfo.email0 || !userInfo.email1) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '이메일을 입력해주세요.',
        isShow: true
      })
    } else if (!userInfo.post || !userInfo.address0 || !userInfo.address1) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '주소를 입력해주세요.',
        isShow: true
      })
    } else {
      authUpdate(userInfo)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          setAlert({
            title: res.title,
            type: 'alert',
            message: res.message,
            isShow: true
          })
          setAuth({
            ...auth,
            address0: userInfo.address0,
            address1: userInfo.address1,
            post: userInfo.post,
            email: userInfo.email0 + '@' + userInfo.email1,
            phone: userInfo.phone0 + userInfo.phone1 + userInfo.phone2
          })
        }
      })
    }
  }

  // 회원탈퇴
  const userWithdrawal = () => {
    authDelete(auth.id)
    .then((res) => {
      console.log(res)
    })
  }

  // 로그인 체크
  useEffect(() => {
    if (!auth.token) {
      navigate('/')
    }
  }, [auth])

  return (
    <MyProfileWrap>
      <TitleWrap>
        <h2>회원정보수정</h2> 
      </TitleWrap>
      <div className="profile-wrap">
        <div className="info-box mo-db">
          <InputBox 
            title='아이디'
            disabled={true}
            value={auth?.userId}
          />
          <InputBox 
            title='비밀번호'
            password={true}
            value={userInfo.password}
            handleChange={(v: string) => {
              setUserInfo({
                ...userInfo,
                password: v
              })
            }}
          />
        </div>
        <div className="info-box w50">
          <InputBox 
            title='이름'
            disabled={true}
            value={auth?.name}
          />
        </div>
        <div className="info-box">
          <InputBox 
            title='연락처'
            disabled={true}
            value={userInfo.phone0}
          />
          <span>-</span>
          <InputBox 
            title=''
            disabled={true}
            value={userInfo.phone1}
          />
          <span>-</span>
          <InputBox 
            title=''
            disabled={true}
            value={userInfo.phone2}
          />
        </div>
        <div className="info-box email-wrap">
          <InputBox 
            title='이메일'
            value={userInfo.email0}
            handleChange={(value: string) => setUserInfo({
              ...userInfo,
              email0: value
            })}
          />
          <span>@</span>
          <InputBox 
            title=''
            value={userInfo.email1}
            handleChange={(value: string) => handleChangeEmail(value)}
          />
          <Select 
            optionList={optionList}
            value={selectValue}
            handleChange={handleChangeSelect}
            flex={1}
          />
        </div>
        <div className="info-box w50">
          <InputBox 
            title='우편번호'
            value={userInfo.post}
            handleChange={(value: string) => setUserInfo({
              ...userInfo,
              post: value
            })}
            handleClick={() => handleAddressModal(true)}
          />
        </div>
        <div className="info-box mo-db">
          <InputBox 
            title='기본주소'
            value={userInfo.address0}
            handleChange={(value: string) => setUserInfo({
              ...userInfo,
              address0: value
            })}
            handleClick={() => handleAddressModal(true)}
          />
          <InputBox 
            title='상세주소'
            value={userInfo.address1}
            handleChange={(value: string) => setUserInfo({
              ...userInfo,
              address1: value
            })}
          />
        </div>
      </div>
      <div className="btn-wrap">
        <button 
          type="button"
          onClick={userWithdrawal}
        >회원탈퇴</button>
        <button 
          type="button"
          onClick={handleSubmit}
        >저장</button>
      </div>
      <Address 
        modal={addressModal}
        handleModal={handleAddressModal}
        handleChange={handleAddress}
      />
    </MyProfileWrap>
  )
}

export default MyProfile