import React, {useState} from 'react'
import {Style} from './style/Tree'
import InputField from '../../module/input'
import Button from '../../module/button'
import {useSetRecoilState} from 'recoil'
import {alertAtom} from '../../../store/recoil/alert'

const Tree = (props: any) => {

  const {item, hasChildren, level, toggle, add, modify, _delete} = props
  const [isModify, setIsModify] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [state, setState] = useState<any>(false)
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Style.Container>
      <Style.Tree>
        {
          !isModify ?
            <>
              <input
                type="checkbox"
                id={item.id}
                onChange={toggle}
                defaultChecked={false}
              />
              <label
                htmlFor={item.id}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <span style={{'paddingLeft': level * 5}}>
                  {item.name}
                  {/*<span className="url">{item.url}</span>*/}
                </span>
              </label>
            </>
            :
            <>
              <Style.TreeItem isLevel={level}>
                <InputField
                  type="text"
                  id="name"
                  name="name"
                  value={state.name ? state.name : item.name}
                  placeholder=""
                  onChange={(e) => handleChange(e)}
                />

                {/*<InputField*/}
                {/*  type="text"*/}
                {/*  id="url"*/}
                {/*  name="url"*/}
                {/*  value={item.url}*/}
                {/*  placeholder=""*/}
                {/*  onChange={(e) => handleChange(e)}*/}
                {/*/>*/}
              </Style.TreeItem>
            </>
        }

        {
          hasChildren && <span className="icon icon-arrow-down">-</span>
        }

        <Style.Action>
          {
            isModify ?
              <Style.ModyfiAction>
                <Button
                  margin={'0 .4rem 0 .4rem'}
                  padding={'.4rem .5rem'}
                  radius={'.5rem'}
                  bgcolor={'#3f3b3f'}
                  color={'#ffffff'}
                  themecolor={'dark-gray'}
                  onClick={() => [modify({
                    id: item.id,
                    name: state.name,
                    code: item.code
                  }), setIsModify(!isModify)]}
                >
                  저장
                </Button>

                <Button
                  margin={'0 0 0 0'}
                  padding={'.4rem .5rem'}
                  radius={'.5rem'}
                  bgcolor={'#3f3b3f'}
                  color={'#ffffff'}
                  themecolor={'dark-gray'}
                  onClick={() => setIsModify(!isModify)}
                >
                  취소
                </Button>
              </Style.ModyfiAction>
              :
              <>
                {isHovering &&
                    <Style.ModyfiAction
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                    >
                      {/*<Button*/}
                      {/*    margin={'0 0 0 0'}*/}
                      {/*    padding={'.4rem .5rem'}*/}
                      {/*    radius={'.5rem'}*/}
                      {/*    bgcolor={'#3f3b3f'}*/}
                      {/*    color={'#ffffff'}*/}
                      {/*    themecolor={'dark-gray'}*/}
                      {/*    onClick={() => setIsAdd(!isAdd)}*/}
                      {/*>*/}
                      {/*  하위 분류 추가*/}
                      {/*</Button>*/}

                      <Button
                          margin={'0 .4rem 0 .4rem'}
                          padding={'.4rem .5rem'}
                          radius={'.5rem'}
                          bgcolor={'#3f3b3f'}
                          color={'#ffffff'}
                          themecolor={'dark-gray'}
                          onClick={() => setIsModify(!isModify)}
                      >
                        수정
                      </Button>

                      <Button
                          margin={'0 0 0 0'}
                          padding={'.4rem .5rem'}
                          radius={'.5rem'}
                          bgcolor={'#3f3b3f'}
                          color={'#ffffff'}
                          themecolor={'dark-gray'}
                          onClick={() => _delete(item)}
                      >
                        삭제
                      </Button>
                    </Style.ModyfiAction>}
              </>
          }
        </Style.Action>
      </Style.Tree>

      {
        isAdd &&
          <Style.TreeItem isLevel={level}>
            <InputField
                type="text"
                id="newLabel"
                name="newLabel"
                value={item.newLabel}
                placeholder="이름"
                onChange={(e) => handleChange(e)}
            />

            {/*<InputField*/}
            {/*    type="text"*/}
            {/*    id="newUrl"*/}
            {/*    name="newUrl"*/}
            {/*    value={item.newUrl}*/}
            {/*    placeholder="경로"*/}
            {/*    onChange={(e) => handleChange(e)}*/}
            {/*/>*/}

            <Style.AddAction>
              <Button
                  margin={'0 0 0 0'}
                  padding={'1rem 4rem'}
                  radius={'.5rem'}
                  bgcolor={'#3f3b3f'}
                  color={'#ffffff'}
                  themecolor={'dark-gray'}
                  onClick={() => add(item)}
              >
                추가
              </Button>

              <Button
                  margin={'0 0 0 0'}
                  padding={'1rem 4rem'}
                  radius={'.5rem'}
                  bgcolor={'#3f3b3f'}
                  color={'#ffffff'}
                  themecolor={'dark-gray'}
                  onClick={() => setIsAdd(!isAdd)}
              >
                취소
              </Button>
            </Style.AddAction>
          </Style.TreeItem>
      }
    </Style.Container>
  )
}

export default Tree
