import styled from "styled-components";

export const PolicyWrap = styled.div`
  height: calc(100vh - 44.75rem);
  padding: 9rem 0;
  background-color: #f1f2f7;
  .box-wrap {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 0.4rem;
    .title-wrap {
      padding: 3.2rem;
      border-bottom: 0.1rem solid #dfdfdf;
      h2 {
        font-size: 2.8rem;
        font-weight: 700;
      }
    }
    .contents-wrap {
      height: calc(100% - 9.3rem);
      padding: 1.6rem;
      .contents {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 1.6rem;
      }
    }
  }
  @media screen and (max-width: 1240px) {
    height: calc(100vh - 36.65rem);
  }
  @media screen and (max-width: 900px) {
    height: calc(100vh - 45.65rem);
  }
  @media screen and (max-width: 768px) {
    height: calc(100vh - 41.45rem);
    padding: 8rem 0;
    .box-wrap {
      .title-wrap {
        padding: 2rem 3rem;
        h2 {
          font-size: 2.8rem;
        }
      }
      .contents-wrap {
        height: calc(100% - 6.9rem);
      }
    }
  }
  @media screen and (max-width: 500px) {
    height: auto;
    padding: 6rem 0;
    .box-wrap {
      height: auto;
      .title-wrap {
        padding: 2rem 1.5rem;
        h2 {
          font-size: 2.4rem;
        }
      }
      .contents-wrap {
        height: auto;
        padding: 1.5rem;
        .contents {
          padding: 0;
          height: 50vh;
        }
      }
    }
  }
`