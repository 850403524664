import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {giftListAtom, giftAtom} from '../recoil/gift'

export const GiftAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [giftList, setGiftList] = useRecoilState(giftListAtom)
  const setGift = useSetRecoilState(giftAtom)

  const regisGift = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('code', params.code)
    formData.append('name', params.name)
    formData.append('content', params.content)
    formData.append('categories', JSON.stringify(params.categories))
    formData.append('options', JSON.stringify(params.options))

    formData.append('soldout', params.soldout)
    formData.append('grade', params.grade)
    formData.append('display', params.display)
    formData.append('display_order', params.display_order)

    params.thumbnails.forEach((item: any, index: number) => {
      formData.append('thumbnails', item.file)
    })

    params.images.forEach((item: any, index: number) => {
      formData.append('images', item.file)
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/product/insert/',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 등록되었습니다.',
          isShow: true
        })
        !params.id && navigate(-1)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateGift = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('code', params.code)
    formData.append('categories', JSON.stringify(params.categories))
    formData.append('name', params.name)
    formData.append('content', params.content)
    formData.append('options', JSON.stringify(params.options))

    formData.append('soldout', params.soldout)
    formData.append('grade', params.grade)
    formData.append('display', params.display)
    formData.append('display_order', params.display_order)

    params.thumbnails && params.thumbnails.forEach((item: any, index: number) => {
      formData.append('thumbnails', item.file)
    })

    params.images && params.images.forEach((item: any, index: number) => {
      formData.append('images', item.file)
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/product/update',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 수정되었습니다.',
          isShow: true
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getGiftList = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/product/list`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setGiftList(response.data.payload)
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getGift = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/product/detail/`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setGift(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteGift = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/product/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        const filteredList = giftList.data.filter(item => !params.ids.includes(item.id))
        setGiftList({
          ...giftList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteCategory = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/product/deleteCategoryItem',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteOption = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/product/deleteOption',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisExcels = async (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('parent', params.parent)
    formData.append('user_id', params.user_id)
    formData.append('file', params.file)
    formData.append('options', JSON.stringify(params.options))

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/product/addJaego/',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.response.data.title,
          type: 'alert',
          message: error.response.data.message,
          isShow: true
        })
      }
    )
  }

  const getJaegoList = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/product/jaegoList`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteJaego = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/product/deleteJaego',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteImages = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/product/deleteImages',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regisGift,
    updateGift,
    getGiftList,
    getGift,
    deleteGift,
    deleteCategory,
    deleteImages,
    deleteOption,
    regisExcels,
    getJaegoList,
    deleteJaego
  }
}
