import {atom} from 'recoil'

interface NameList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    order_name: string
    priceFrom: number
    priceTo: number
  }[]
}

interface Name {
  id: number
  order_name: string
  priceFrom: number
  priceTo: number
}

export const nameListAtom = atom<NameList>({
  key: 'nameListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const nameAtom = atom<Name>({
  key: 'nameAtom',
  default: {
    id: 0,
    order_name: '',
    priceFrom: 0,
    priceTo: 0
  }
})
