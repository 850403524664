import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {tempIdAtom, noticeListAtom, noticeAtom} from '../recoil/notice'

export const NoticeAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setTempId = useSetRecoilState(tempIdAtom)
  const [noticeList, setNoticeList] = useRecoilState(noticeListAtom)
  const setNotice = useSetRecoilState(noticeAtom)

  const create = (params: any) => {
    loader({type: 'bar', isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/notice/createNoticeId',
      params: params
    }).then(
      response => {
        loader({type: 'bar', isLoading: false})
        setTempId(response.data.payload)
      },
      error => {
        loader({type: 'bar', isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getNoticeList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/notice/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setNoticeList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getNotice = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/notice/detail`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setNotice(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisNotice = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: params.id ? `/notice/update` : '/notice/insert',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate('/admin/notice')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteNotice = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/notice/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = noticeList.data.filter(item => !params.ids.includes(item.id))
        setNoticeList({
          ...noticeList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    create,
    getNoticeList,
    getNotice,
    regisNotice,
    deleteNotice
  }
}
