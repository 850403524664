import React from 'react'
import { AboutWrap, TitleWrap } from './styles'

const About = () => {
  return (
    <AboutWrap>
      <div className='container'>
        <TitleWrap>
          <h2>회사소개</h2>
        </TitleWrap>
        <div className='img-wrap'>
          <img src='/image/about-img.png' alt='회사소개 이미지' />
        </div>
        <div className='text-wrap'>
          <p>
            <span>페이스토리상품권</span>은 쿠폰스토리의 자회사로 <span>상품권 대량 매입의 표준</span>을 제공하며<br/>
            <b>24시간 편리하고 안전하게 구매 가능합니다</b>
          </p>
        </div>
        <div className='info-wrap'>
          <div className='info'>
            <dl>
              <dt>업종</dt>
              <dd>상품권유통 / 서비스</dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>광주광역시 북구 첨단과기로 208번길 17-19, 308호</dd>
            </dl>
            <dl>
              <dt>전화번호</dt>
              <dd>02-1800-3712</dd>
            </dl>
            <dl>
              <dt>휴일</dt>
              <dd>연중 무휴(24시간 영업)</dd>
            </dl>
          </div>
          <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3259.2391089757643!2d126.84958007650721!3d35.22541735487721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718e13f69d1323%3A0x1249cd320ad29ca0!2z6rSR7KO86rSR7Jet7IucIOu2geq1rCDssqjri6jqs7zquLDroZwyMDjrsojquLggMTctMTkgMzA47Zi4!5e0!3m2!1sko!2skr!4v1716195939740!5m2!1sko!2skr"></iframe>
          </div>
        </div>
      </div>
    </AboutWrap>
  )
}

export default About
