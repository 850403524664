import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {categoryListAtom} from '../recoil/category'

interface PaginationInterface {
  page: number
  size: number
}

export const CategoryAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [categoryList, setCategoryList] = useRecoilState(categoryListAtom)

  const regisCategory = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/category/insert',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        return {
          id: response.data.payload,
          name: params.name
        }

        setAlert({
          title: '확인',
          type: 'alert',
          message: '정상적으로 등록되었습니다.',
          isShow: true
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getCategoryList = async (params: PaginationInterface) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/category/list',
      params: params
    }).then((res) => {
      loader({isLoading: false})
      return res.data
    }, (error) => {
      loader({isLoading: false})
      setAlert({
        title: error.name,
        type: 'alert',
        message: error.message,
        isShow: true
      })
    })
  }


  const deleteCategory = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/category/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateCategory = async (params: any) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/category/update',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const categoryDetail = async (id: number) => {

    loader({
      isLoading: true
    })

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/category/detail',
      params: {id: id}
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getHotdealId = async () => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/category/hotdeal',
      params: {}
    }).then(
      response => {
        loader({isLoading: false})
        return response.data
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regisCategory,
    getCategoryList,
    deleteCategory,
    updateCategory,
    categoryDetail,
    getHotdealId
  }
}
