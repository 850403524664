import React from 'react'
import { ShopItemWrap } from './styles'
import { Link } from 'react-router-dom'
import { ShopItemProps } from './types'

const ShopItem = ({item}: ShopItemProps) => {
  // 모바일 이미지
  const image = item?.thumbnail && JSON.parse(item.thumbnail)[0].path
  
  return (
    <ShopItemWrap>
      <Link 
        to={`/shop/detail/${item.product_id || item.id}`}
        className='item'
      >
        <div 
          className='figure'
          style={{background: `url('${process.env.REACT_APP_BASE_URL}${item.img || image}') no-repeat center / cover`}}
        ></div>
        <div className='info'>
          <div className='title'>{item.name}</div>
          <div className='text'>권종 선택구매</div>
        </div>
      </Link>
    </ShopItemWrap>
  )
}

export default ShopItem