import {createGlobalStyle} from 'styled-components'

export const Layout = createGlobalStyle`
  #root {
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: column;
    height: 100%;
    min-width: 32rem;
  }

  main {
    display: flex;
    flex: 1;
    overflow: auto;
    position: relative;

    .nav {
      display: flex;
      flex: 1;
      align-self: stretch;
      max-width: 18rem;
      border-right: .1rem solid #efebef;
      padding: 0 2rem;
      position: sticky;
      top: 0;

      @media screen and (max-width: 500px) {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 56px;
        z-index: 9;
        background-color: #fff;
        max-width: unset;
        overflow-y: auto;
        &.active {
          display: block;
        }
      }
    }
    
    .content {
      display: flex;
      flex: 1;
      height: fit-content;
      //max-width: 140rem;
      min-height:100%;
      padding: 2.5rem 2rem;
      position: relative;
      
      .inner {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        min-height: 100%;
        position: relative;
      }
    }
  }
  
  @media (max-width: 768px) {
    body,
    html {
      font-size: 9px !important;
    }
  }

  @media (max-width: 600px) {
    body,
    html {
      font-size: 8px !important;
    }
  }

  @media (max-width: 375px) {
    body,
    html {
      font-size: 7px !important;
    }
  }

  @media (max-width: 320px) {
    body,
    html {
      font-size: 6px !important;
    }
  }
`;
