export const Common = {
  isNumber: (string: string) => {
    return isNaN(string as any);
  },
  isEmail: (string: string) => {
    const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regEmail.test(string)
  },
  isMinLength: (string: string, length: number) => {
    return (string == null || (string.trim().length >= length))
  },
  numberFormat: (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  toString: (string: string) => {
    return string.replaceAll(',', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
