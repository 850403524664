import React, {useState} from 'react'
import {useMatch} from 'react-router-dom'
import styled from 'styled-components'

import Kakao from './Kakao'
import Naver from './Naver'

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 4rem;
`

const Social = styled.ul`
  display: flex;
  flex: 1;
`

const SocialItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  overflow: hidden;
  word-break: keep-all;
  cursor: pointer;

  span {
    display: inline-block;
    font-size: 1.2rem;
    text-align: center;
  }

  .icon-naver {
    width: 5rem;
    height: 5rem;
    background-image: url("../../../images/social-naver.png");
    background-size: 2.1rem;
    background-position: 0 center;
    background-repeat: no-repeat;
  }

  .icon-kakao {
    width: 5rem;
    height: 5rem;
    background-image: url("../../../images/social-kakao.png");
    background-size: 2.8rem;
    background-position: right 1.2rem;
    background-repeat: no-repeat;
  }
`
const Index = () => {

  const returnType = useMatch("/:path/:type")
  const [type, setType] = useState<string>('')

  const getType = (type: string | undefined) => {
    switch (type) {
      case 'kakao':
        return <Kakao/>
      case 'naver':
        return <Naver/>
      default:
        return null
    }
  }

  return (
    <Container>
      <Social>
        <SocialItem onClick={() => setType('naver')}>
          <span className="icon-naver"></span>
        </SocialItem>

        <SocialItem onClick={() => setType('kakao')}>
          <span className="icon-kakao"></span>
        </SocialItem>
      </Social>
      {
        getType(type ? type : returnType?.params.type)
      }
    </Container>
  )
}
export default Index
