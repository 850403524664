import {atom} from 'recoil'

interface PurchaseList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    image: string;
    prod_title: string;
    price: number;
    barcode: string;
    end_date: string;
    status: string;
    created_at: string;
    completed_at: string;
    reject_reason: string;
  }[]
}
export const purchaseListAtom = atom<PurchaseList>({
  key: 'purchaseListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})
