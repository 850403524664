import { createGlobalStyle } from 'styled-components'

export const Common = createGlobalStyle`
  .hide-scroll-bar {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .sep {
    margin: 0 .3rem;

    &.before {
      &:before {
        content: "|";
        color: #e0e0e0;
        display: inline-block;
        padding: 0 .2rem;
        font-size: 1rem;
        font-weight: 600;
      }
    }

    &:after {
      content: "|";
      color: #e0e0e0;
      display: inline-block;
      padding: 0 .2rem;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .badge {
    position: relative;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -.8rem;
      right: -.8rem;
      border-radius: 50%;
      width: 1.8rem;
      height: 1.8rem;
      border: .1rem #E40B2C;
      background: #E40B2C;
      font-size: .3rem;
      font-weight: 500;
      color: #ffffff;
      padding: .6rem .6rem .7rem .5rem;
      font-family: 'SpoqaHanSans'
    }
  }

  .hand {
    cursor: pointer;
  }
`;
