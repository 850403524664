import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {qnaListAtom, qnaAtom} from '../recoil/qna'

export const QnaAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [qnaList, setQnaList] = useRecoilState(qnaListAtom)
  const setQna = useSetRecoilState(qnaAtom)

  const getQnaList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/board/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setQnaList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getQnaListUpdate = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/inquiry/adminInquiryUpdateList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setQnaList(prevQnaList => ({
          ...prevQnaList,
          data: [
            ...prevQnaList.data,
            ...response.data.payload.data
          ],
          pageInfo: {
            ...prevQnaList.pageInfo,
            total: prevQnaList.pageInfo.total - params.size
          }
        }))
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getQna = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: `/board/detail`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setQna(response.data.payload.data)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisAnswer = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/board/answer',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAlert({
          title: '확인',
          type: 'alert',
          message: '등록되었습니다.',
          isShow: true
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteQna = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/board/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = qnaList.data.filter(item => !params.ids.includes(item.id))
        setQnaList({
          ...qnaList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getQnaList,
    getQna,
    regisAnswer,
    deleteQna
  }
}
