import React, { useEffect, useState } from 'react'
import { InquiryWrap, TitleWrap } from './styles'
import Board from '../../components/board'
import { Link, useLocation } from 'react-router-dom'
import { BoardAction } from '../../store/action/board'
import { useRecoilValue } from 'recoil'
import { boardListAtom } from '../../store/recoil/board'

const Inquiry = () => {

  // location
  const location = useLocation()
  const currentPage = new URLSearchParams(location.search).get('page') || 0

  // Board Action
  const { getBoardList } = BoardAction()

  // Search
  const [search, setSearch] = useState({
    keyword: '',
    searchType: ''
  })

  const getInquiryList = (keyword: string, searchType: string) => {
    setSearch({
      keyword: keyword,
      searchType: searchType
    })
    getBoardList({
      page: Number(currentPage),
      size: 10,
      searchType: searchType,
      keyword: keyword
    })
  }

  // 공지사항 리스트 가져오기
  useEffect(() => {
    getInquiryList(search.keyword, search.searchType)
  }, [currentPage])

  // 문의하기 리스트
  const inquiryList = useRecoilValue(boardListAtom)

  return (
    <InquiryWrap>
      <div className='container'>
        <TitleWrap>
          <h2>문의하기</h2>
        </TitleWrap>
        <Board 
          list={inquiryList.data}
          link={'/inquiry'}
          totalPage={inquiryList.pageInfo.total}
          size={inquiryList.pageInfo.size}
          searchEvent={getInquiryList}
        />
        <div className="btn-wrap">
          <Link to={'/inquiry/write'}>
            <img src="/image/inquiry-icon.png" alt="문의하기 버튼" />
            <span>문의하기</span>
          </Link>
        </div>
      </div>
    </InquiryWrap>
  )
}

export default Inquiry