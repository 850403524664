import styled from 'styled-components'

const Alert = styled.div`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999999;
`

const Modal = styled.div`
  text-align: left;
  padding: 0 1rem;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-width: 32rem;
  max-width: 40rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 1.2rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 2rem 6rem -.2rem rgba(27, 33, 58, 0.4);
  border-radius: 1.5rem !important;
  padding: 1rem;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Close = styled.button`
  background: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;
  opacity: .3;
  margin-right: 1rem;
  cursor: pointer;
  z-index: auto;
`

const Title = styled.div`
  font-size: 1.9rem !important;
  font-weight: 500 !important;
  color: #333333 !important;
  padding: 1rem 0 0 1rem;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  overflow: auto;
  height: 100%;
  padding: 1rem 0 1rem 0;
`

const Description = styled.div`
  font-size: 1.2em !important;
  font-weight: 500;
  color: #999999;
  text-align: left;
  padding: 0 0 0 1rem;
`

const Footer = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
`

export const Style = {
  Alert,
  Modal,
  Content,
  Header,
  Close,
  Title,
  Body,
  Description,
  Footer
}
