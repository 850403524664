import styled from "styled-components";

export const LoginModalWrap = styled.div`
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  .bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.46);
    opacity: 0;
    transition: all ease 0.3s;
  }
  .close-btn {
    position: absolute;
    right: 1.6rem;
    top: 1.9rem;
    span {
      font-size: 3.6rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .login-box {
    max-width: 48rem;
    width: 100%;
    height: auto;
    box-shadow: 0 0.3rem 0.1rem -0.2rem rgba(0,0,0,0.2), 0 0.2rem 0.2rem 0 rgba(0,0,0,0.14), 0 0.1rem 0.5rem 0 rgba(0,0,0,0.12);
    border-radius: 0.8rem;
    margin: 0 auto;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all ease 0.3s;
    overflow: hidden;
    z-index: 9;
    .title-wrap {
      padding: 2.4rem 0;
      border-bottom: 0.1rem solid #dfdfdf;
      h2 {
        font-size: 2.4rem;
        font-weight: 500;
        text-align: center;
      }
    }
    .info-wrap {
      padding: 3.2rem;
    }
    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2.4rem;
      border-top: 0.1rem solid #dfdfdf;
      > a {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  &.active {
    display: block;
    .bg {
      opacity: 1;
    }
    .login-box {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`