import React, { useEffect, useState } from 'react'
import { ShopWrap, TitleWrap } from './styles'
import ShopList from '../../components/shop/list'
import { UserProductAction } from '../../store/action/userProduct'
import { useLocation } from 'react-router'
import { CategoryAction } from '../../store/action'

const Shop = () => {

  // location
  const location = useLocation()

  // Product Action
  const { getProductList, getProductAllList } = UserProductAction()

  // Category Action
  const { categoryDetail } = CategoryAction()

  // 제목
  const [shopTitle, setShopTitle] = useState('')

  // 상품 리스트
  const [shopList, setShopList] = useState([])

  const [pagination, setPagination] = useState({
    page: 0,
    size: 100,
    searchType: '',
    keyword: ''
  })

  useEffect(() => {
    const id = location.search.split('category=')[1]
    if (id) {
      categoryDetail(Number(id))
      .then((res) => {
        setShopTitle(res.payload.name)
      })
      getProductList(Number(id))
      .then((res) => {
        setShopList(res.data)
      })
    } else {
      getProductAllList(pagination)
      .then((res) => {
        setShopTitle('모바일상품권')
        setShopList(res.data)
      })
    }
  }, [location])

  return (
    <ShopWrap>
      <div className="container">
        <TitleWrap>
          <h2>{shopTitle}</h2>
        </TitleWrap>
        <ShopList 
          list={shopList}
        />
      </div>
    </ShopWrap>
  )
}

export default Shop