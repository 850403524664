import React, {useState, useEffect} from 'react'
import Tree from './tree'
import {CategoryAction} from '../../../store/action'

const Category = (props: any) => {

  const {item, level, deleteItem, updateItem} = props
  const [selected, setSelected] = useState(item.selected ?? false)
  const [sub, setSub] = useState()
  const hasChildren = item.children && item.children.length > 0
  const {deleteCategory, updateCategory} = CategoryAction()

  const renderTrees = () => {
    if (hasChildren) {
      const newLevel = level + 1

      return item.children.map((child: any) => {
        return <Category
          key={child.id}
          item={child}
          level={newLevel}
          selected={selected}
          add={(item: any) => add(item)}
          modify={(item: any) => update(item)}
          _delete={(item: any) => _delete(item)}
        />
      })
    }
  }

  const toggleSelected = () => {
    setSelected((isSelected: any) => !isSelected)
  }

  const add = (item: any) => {
    updateCategory({
      id: item.id,
      name: item.name,
      code: item.code
    }).then(() => {
      console.log("========")
    })
  }

  const update = (item: any) => {
    updateCategory({
      id: item.id,
      name: item.name,
      code: item.code
    }).then(() => {
      updateItem(item)
    })
  }

  const _delete = (item: any) => {
    deleteCategory({id: item.id}).then(() => {
      deleteItem(item.id)
    })
  }

  return (
    <React.Fragment>
      <Tree
        item={item}
        selected={selected}
        hasChildren={hasChildren}
        level={level}
        toggle={toggleSelected}
        add={(item: any) => add(item)}
        modify={(item: any) => update(item)}
        _delete={(item: any) => _delete(item)}
      />
      {selected && renderTrees()}
    </React.Fragment>
  )
}

export default Category
