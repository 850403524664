import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'

interface PaginationInterface {
  page: number
  size: number
}

export const UserPopupAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)

  const getPopupList = async (params: PaginationInterface) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/popup/list`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getPopupList
  }
}
