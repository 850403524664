import React, {useState} from 'react'
import {InputBoxWrap} from './styles'
import {InputBoxProps} from './types'
import Input from '../../module/input'

const InputBox = ({
                    title,
                    marginTop,
                    password,
                    disabled,
                    value,
                    handleChange,
                    handleClick,
                    handleKeyDown,
                    type,
                    maxLength
                  }: InputBoxProps) => {

  const [focusActive, setFocusActive] = useState(false)
  const handleFocus = () => {
    if (!disabled) {
      setFocusActive(true)
    }
  }
  const handleBlur = () => {
    setFocusActive(false)
  }

  const [passwordType, setPasswordType] = useState('password')
  const handlePasswordType = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }

  return (
    <InputBoxWrap
      style={marginTop ? {marginTop: marginTop} : {}}
    >
      <span className={disabled ? 'disabled' : focusActive ? 'focus' : ''}>{title}</span>
      <div className={focusActive ? 'input-wrap focus' : 'input-wrap'}>
        {
          handleClick ? (
            <button 
              type='button'
              className='no-select-input'
              onClick={handleClick}
            >
              <span>{value ? value : ''}</span>
            </button>
          ) : (
            <Input
              type={password ? passwordType : type === 'number' ? 'number' : 'text'}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              disabled={disabled}
              value={value ? value : ''}
              borderDashed={disabled ? true : false}
              handleChange={(value) => handleChange && handleChange(value)}
              handleKeyDown={handleKeyDown}
              maxLength={maxLength}
            />
          )
        }
        {
          password && (
            <button
              type="button"
              className={focusActive ? 'focus material-icons password-icon' : 'material-icons password-icon'}
              onClick={handlePasswordType}
            >
              <i className="material-icons" translate="no">{
                passwordType === 'password' ? 'visibility_off' : 'remove_red_eye'
              }</i>
            </button>
          )
        }
      </div>
    </InputBoxWrap>
  )
}

export default InputBox
