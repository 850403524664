import React, { useEffect, useState } from 'react'
import { OrderBoxWrap } from './styles'
import Button from '../../module/button'
import BorderInput from '../inputBox/BorderInput'
import Select from '../../module/select'
import {OrderAction} from '../../store/action'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authAtom } from '../../store/recoil/auth'
import { useLocation, useNavigate } from 'react-router'
import { alertAtom } from '../../store/recoil/alert'
import MainPay from "../../module/mainPay";
import { OrderPriceAction } from '../../store/action/orderPrice'
import CallMainPay from "../../module/mainPay/callMainPay";
import wingPay from "../../module/wingPay";

const OrderBox = () => {

  // 로그인 한 유저
  const auth = useRecoilValue(authAtom)

  const [amount, setAmount] = useState(0)

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Alert
  const setAlert = useSetRecoilState(alertAtom)

  // Order Action
  const {order} = OrderAction()
  const {getOrderPirceTitle} = OrderPriceAction()

  // 결제할 상품 옵션
  const options = location?.state?.shopOptions

  // 결제 상품 정보
  const shopDetail = location?.state?.shopDetail

  // 결제 정보들
  const [orderInfo, setOrderInfo] = useState({
    orderName: '',
    user_id: 0,
    total: 0,
    pay_method: "",
    totalCnt: 0,
    receiver_name: "",
    receiver_phone: "",
    options: [
      {
        id: 0,
        cnt: 0,
        original_price: 0,
        tax: 0,
        parent: 0
      }
    ]
  })

  // 쿠폰 받을 휴대전화
  const [selectValue, setSelectValue] = useState('010')
  const [phone, setPhone] = useState('')
  const optionList = [
    {
      value: '010',
      label: '010'
    },
    {
      value: '011',
      label: '011'
    },
    {
      value: '016',
      label: '016'
    },
    {
      value: '019',
      label: '019'
    }
  ]
  const handleSelectChange = (v: string) => {
    setSelectValue(v)
  }

  // 주문 결제 시 상품 이름
  const [orderProductName, setOrderProductName] = useState('')

  // 결제하기
  const handleSubmit = () => {

    const user = {name: auth.name, email: auth.email, phone: auth.phone, birth: auth.birth}
    const product = orderInfo.options.filter(it => it.cnt > 0).map((item: any) => {
      return {
        prodId: item.id,
        name: orderProductName,
        qty: item.cnt,
        price: (item.original_price + item.tax) * item.cnt,
        desc: ''
      }
    })

    // console.log(auth)
    // console.log(orderInfo)
    wingPay(
      user,
      product,
      null,
      order,
      orderInfo,
      () => navigate('/my/order')
    )

    // console.log('######### ', orderInfo )
    // CallMainPay(auth?.name,
    //   // totalPrice?.toString(),
    //   totalPrice,
    //   orderProductName || shopDetail?.name,
    //   '여분필드',
    //   orderInfo,
    //   order,
    //   navigate,
    //   setAlert)
  }

  // 총 주문 금액 / 카운트
  const totalPrice = options?.reduce((acc: any, cur: any) => acc + ((cur.cnt || 0) * (cur.original_price + cur.tax)), 0)
  const totalCnt = options?.reduce((acc: any, cur: any) => acc + cur.cnt, 0)

  // 상품 정보 가져오기
  useEffect(() => {
    setAmount(totalPrice)

    getOrderPirceTitle(totalPrice)
    .then((res) => {
      setOrderProductName(res.payload)
      setOrderInfo({
        ...orderInfo,
        orderName: res.payload,
        user_id: auth?.id,
        total: totalPrice,
        pay_method: "카드결제",
        totalCnt: totalCnt,
        receiver_name: auth?.name,
        //receiver_phone: selectValue + phone,
        receiver_phone: auth?.phone,
        options: options
      })
    })

    setSelectValue(auth?.phone?.slice(0, 3))
    setPhone(auth?.phone?.slice(3, 11))
  }, [auth, options])

  // 상품 정보 없이 접근 시 home으로 보내기
  useEffect(() => {
    if (!options) {
      navigate('/')
      setAlert({
        title: '오류',
        type: 'alert',
        message: '정상적이지 않은 접근입니다.',
        isShow: true
      })
    }
  }, [auth, location])

  return (
    <OrderBoxWrap>
      <div className="left-box">
        <div className="order-type">
          {/*<MainPay */}
          {/*  username={auth?.name} */}
          {/*  amount={totalPrice?.toString()} */}
          {/*  goodsname={orderProductName || shopDetail?.name}*/}
          {/*/>*/}
          <Button
            text='신용카드결제'
            borderColor='#d9d9d9'
            textColor='rgba(0, 0, 0, 0.87)'
            backgroundColor='#d9d9d9'
            icon='/image/card-icon.png'
            boxShadow='0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
          />
        </div>
        <div className="coupon-wrap">
          <h4>쿠폰을 받을 휴대폰 번호</h4>
          <div className="phone-wrap">
            <Select
              optionList={optionList}
              value={selectValue}
              handleChange={handleSelectChange}
              borderType={true}
              disabled={true}
            />
            <BorderInput
              title='-없이 숫자만 입력'
              closeNone={true}
              value={phone}
              handleChange={(v) => setPhone(v)}
              disabled={true}
            />
          </div>
          <div className="info-text">
            <span>* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.</span>
            <span>* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.</span>
          </div>
        </div>
      </div>
      <div className="right-box">
        <div className="price-wrap">
          <dl>
            <dt>총 상품가격</dt>
            <dd>{Number(totalPrice).toLocaleString('ko-KR')} 원</dd>
          </dl>
          <dl>
            <dt>총 결제 예상 금액</dt>
            <dd>{Number(totalPrice).toLocaleString('ko-KR')} 원</dd>
          </dl>
        </div>
        <Button
          text='결제하기'
          borderColor='#b868bf'
          textColor='#fff'
          backgroundColor='#b868bf'
          handleClick={handleSubmit}
        />
      </div>
    </OrderBoxWrap>
  )
}

export default OrderBox
