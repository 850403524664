import styled from "styled-components";

export const OrderWrap = styled.div`
  padding: 6rem 0;
`

export const TitleWrap = styled.div`
  padding-left: 3.2rem;
  margin-bottom: 4.8rem;
  h2 {
    font-size: 2.8rem;
    font-weight: 500;
    color: #222;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 4.5rem;
    h2 {
      text-align: center;
      font-size: 2.6rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 4rem;
    h2 {
      font-size: 2.4rem;
    }
  }
`