import styled from 'styled-components'

const FooterWrap = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0;
  color: #666666;
  border-top: .1rem solid #efebef;
  background: #fcfcfc;
  font-size: 1.1rem;
  font-weight: 400;
`

const Footer = () => {
  return(
    <FooterWrap>
        ⓒ BONMI Corp.
    </FooterWrap>
  )
}

export default Footer
