import React, { useEffect, useState } from 'react'
import { TextareaBoxWrap } from './styles'
import { TextareaBoxProps } from './types'
import Input from '../../module/input'

const TextareaBox = ({title, marginTop, value, handleChange}: TextareaBoxProps) => {

  const [focusActive, setFocusActive] = useState(false)
  const [textValue, setTextValue] = useState('')

  const handleFocus = () => {
    setFocusActive(true)
  }
  const handleBlur = () => {
    setFocusActive(false)
  }

  const handleChangeValue = (v: string) => {
    setTextValue(v)
    handleChange(v)
  }

  useEffect(() => {
    setTextValue(value)
  }, [value])

  return (
    <TextareaBoxWrap 
      className={focusActive ? 'focus' : ''}
      style={marginTop ? {marginTop: marginTop} : {}}
    >
      <span className={focusActive ? 'focus' : ''}>{title}</span>
      <textarea
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={textValue}
        onChange={(e) => handleChangeValue(e.target.value)}
      ></textarea>
    </TextareaBoxWrap>
  )
}

export default TextareaBox