import React, { useEffect, useState } from 'react'
import { InquiryWrap, TitleWrap } from './styles'
import BoardDetail from '../../components/board/Detail'
import { BoardAction } from '../../store/action/board'
import { useLocation } from 'react-router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { boardAtom } from '../../store/recoil/board'
import moment from 'moment'
import PasswordCheck from '../../components/passwordCheck'
import { alertAtom } from '../../store/recoil/alert'

const InquiryDetail = () => {

  // Location
  const location = useLocation()

  // Alert
  const setAlert = useSetRecoilState(alertAtom)

  // Board Action
  const {getBoardDetail, boardPassCheck} = BoardAction()

  // 문의하기 데이터
  const inquiry = useRecoilValue(boardAtom)

  // 비밀글
  const [isSecrete, setIsSecrete] = useState(1);

  // 문의하기 상세 가져오기
  useEffect(() => {
    !(isSecrete === 1) && getBoardDetail({
      id: Number(location.pathname.split('/')[3])
    })
  }, [location, isSecrete])

  // 비밀글 확인
  useEffect(() => {
    setIsSecrete(Number(location?.state?.isSecrete))
  }, [location])

  // 비밀글 패스워드 체크
  const handleCheckPass =(password: string) => {
    boardPassCheck({
      password: password,
      id: Number(location.pathname.split('/')[3])
    }).then((res: any) => {
      if (res.matched) {
        setIsSecrete(0)
      } else {
        setAlert({
          title: '패스워드 체크',
          type: 'alert',
          message: '패스워드가 일치하지 않습니다.',
          isShow: true
        })
      }
    })
  }

  return (
    <InquiryWrap>
      <div className="container">
        <TitleWrap>
          <h2>문의하기</h2>
        </TitleWrap>
        {
          isSecrete === 1 ? (
            <PasswordCheck 
              handleCheckPass={handleCheckPass}
            />
          ) : (
            <BoardDetail 
              title={inquiry?.title}
              date={moment(inquiry?.ask_at).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')}
              name={inquiry?.author}
              count={inquiry?.count}
              contents={inquiry?.content}
              file={inquiry?.files}
            />
          )
        }
      </div>
    </InquiryWrap>
  )
}

export default InquiryDetail