import React, { useState } from 'react'
import { SearchWrap } from './styles'
import { SearchProps } from './types'
import { useNavigate } from 'react-router'

const Search = ({searchEvent, link}: SearchProps) => {

  const navigate = useNavigate()

  // 조건 검색
  const [selectValue, setSelectValue] = useState('제목')
  const handleChangeSelect = (v: string) => {
    setSelectValue(v)
  }

  // 키워드 검색
  const [keyword, setKeyword] = useState('')
  const handleChangeKeyword = (v: string) => {
    setKeyword(v)
  }

  const handleSubmit = () => {
    searchEvent && searchEvent(keyword, selectValue)
    navigate(`${link}?page=0`)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <SearchWrap>
      <select
        value={selectValue}
        onChange={(e) => handleChangeSelect(e.target.value)}
      >
        <option value="제목">제목</option>
        <option value="내용">내용</option>
        <option value="제목+내용">제목+내용</option>
        <option value="작성자">작성자</option>
        <option value="아이디">아이디</option>
      </select>
      <input 
        type='text' 
        value={keyword}
        onChange={(e) => handleChangeKeyword(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button 
        type='button'
        onClick={() => handleSubmit()}
      >
        <img src='/image/search-icon.png' alt='돋보기 아이콘' />
        <span>검색</span>
      </button>
    </SearchWrap>
  )
}

export default Search